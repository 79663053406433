import create from "zustand";
import { City, Menus, PaymentPayload, PermitForm } from 'store/Entities';
import { CityApplicant, SideBarOption } from './Entities';

interface AppState {
    isAuth: boolean
    setAuth: (status: boolean) => void,
    user: User | undefined
    setUser: (user: User) => void,
    locations: Locations,
    setLocations:(locations: AppState['locations']) => void,
    states: States[],
    setStates: (states: States[]) => void,
    cities: Cities[],
    setCities: (cities: Cities[]) => void,
    currentPermitForm: PermitForm,
    setCurrentPermitForm: (currentPermitForm: PermitForm) => void,
    checkoutStep: number,
    setCheckoutStep: (checkoutStep: number) => void,
    paymentPayload: PaymentPayload,
    setPaymentPayload: (paymentPayload: PaymentPayload) => void,
    anonymousToken: string,
    setAnonymousToken: (anonymousToken: string) => void,
    citizenDashboardStep: number,
    setCitizenDashboardStep: (citizenDashboardStep: number) => void,
    applicants: CityApplicant[],
    setApplicants: (applicants: Array<CityApplicant>) => void,
    currentApplicant: CityApplicant,
    setCurrentApplicant: (currentApplicant: CityApplicant) => void,
    currentApplicants: number[],
    setCurrentApplicants: (currentApplicants: number[]) => void,
    currentSideBar: Menus,
    setCurrentSidebar: (currentSideBar: Menus) => void; 
    currentCity: City,
    setCurrentCity: (currentCity: City) => void;
    passwordStep: number,
    setPasswordStep: (resetPasswordStep: number) => void;
    currentFee: number,
    setCurrentFee: (currentFee: AppState['currentFee']) => void;
    sidebarDialog: 'city' | 'fee' | 'closed';
    setSidebarDialog: (sidebarDialog: AppState['sidebarDialog']) => void;
}

const locations: AppState['locations'] = {
    states: [],
    cities: []
}

export const useStore = create<AppState>(set => {
    return {
        isAuth: false,
        setAuth: (status: boolean) => set(() => ({ isAuth: status })),
        user: undefined,
        setUser: (user: User) => set(() => ({ user })),
        locations,
        setLocations: (locations: AppState['locations']) => set(() => ({ locations })),
        states: [],
        setStates: (states) => set({ states }),
        cities: [],
        setCities: (cities) => set({ cities }),
        currentPermitForm: new PermitForm(),
        setCurrentPermitForm: currentPermitForm => set({ currentPermitForm }),
        checkoutStep: 0,
        setCheckoutStep: checkoutStep => set({ checkoutStep }),
        paymentPayload: new PaymentPayload(),
        setPaymentPayload: (paymentPayload) => set({ paymentPayload }),
        anonymousToken: '',
        setAnonymousToken: anonymousToken => set({ anonymousToken }),
        citizenDashboardStep: 0,
        setCitizenDashboardStep: citizenDashboardStep => set({ citizenDashboardStep }),
        applicants: [],
        setApplicants: applicants => set({ applicants }),
        currentApplicant: new CityApplicant(),
        setCurrentApplicant: currentApplicant => set({ currentApplicant }),
        currentApplicants: [],
        setCurrentApplicants: currentApplicants => set({ currentApplicants }),
        currentSideBar: SideBarOption.CLOSE,
        setCurrentSidebar: currentSideBar => set({ currentSideBar }),
        currentCity: new City(),
        setCurrentCity: currentCity => set({ currentCity }),
        passwordStep: 1,
        setPasswordStep: passwordStep => set({ passwordStep }),
        currentFee: 0,
        setCurrentFee: currentFee => set({ currentFee }),
        sidebarDialog: 'closed',
        setSidebarDialog: sidebarDialog => set({ sidebarDialog })
    }
})

export const useIsAuth = () => useStore(state => state.isAuth);
export const useUser = () => useStore(({ user, setUser }) => ({ user, setUser }))
export const useLocations = () => useStore(({locations, setLocations}) => ({ locations, setLocations }));
export const useStates = () => useStore(({ states, setStates }) => ({ states, setStates }));
export const useCities = () => useStore(({ cities, setCities }) => ({ cities, setCities }));
export const useCurrentPermit  = () => useStore(({ currentPermitForm, setCurrentPermitForm }) => ({ currentPermitForm, setCurrentPermitForm }));
export const useCheckoutStep = () => useStore(({checkoutStep, setCheckoutStep }) => ({checkoutStep, setCheckoutStep }))
export const usePaymentPayload = () => useStore(({ paymentPayload, setPaymentPayload }) => ({ paymentPayload, setPaymentPayload }))
export const useAnonymousToken = () => useStore(({ anonymousToken, setAnonymousToken }) => ({ anonymousToken, setAnonymousToken }))
export const useCitizenDashboardStep = () => useStore(({ citizenDashboardStep, setCitizenDashboardStep }) => ({ citizenDashboardStep, setCitizenDashboardStep }))
export const useApplicants = () => useStore(({ applicants, setApplicants }) => ({ applicants, setApplicants }));
export const useCurrentApplicant = () => useStore(({ currentApplicant, setCurrentApplicant }) => ({ currentApplicant, setCurrentApplicant }));
export const useCurrentApplicants = () => useStore(({ currentApplicants, setCurrentApplicants }) => ({ currentApplicants, setCurrentApplicants }));
export const useCurrentSidebar = () => useStore(({ currentSideBar, setCurrentSidebar }) => ({ currentSideBar, setCurrentSidebar }));
export const useCurrentCity = () => useStore(({ currentCity, setCurrentCity }) => ({ currentCity, setCurrentCity }));
export const usePasswordStep = () => useStore(({ passwordStep, setPasswordStep }) => ({ passwordStep, setPasswordStep }));
export const useCurrentFee = () => useStore(({ currentFee, setCurrentFee }) => ({ currentFee, setCurrentFee }));
export const useSideBarDialog = () => useStore(({sidebarDialog, setSidebarDialog}) => ({ sidebarDialog, setSidebarDialog }));
