import { useState, useEffect } from 'react';
import Select from 'react-select';
import { IconBox } from 'components/IconBox';
import { MdSearch } from 'react-icons/md';
import { useApi } from 'hooks/useApi';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/routes';
import { useCurrentFee, useStates } from 'store';
import { useCities, useCurrentPermit } from '../../store/index';
import { PermitForm } from 'store/Entities';

const styles = (width: string) => ({
  control: (styles: any, state: any) => ({
    ...styles,
    border: '0 !important',
    boxShadow: '0 !important',
    outline: '0',
    borderRadius: 8,
    width,
    opacity: state.isDisabled ? 0.95 : 1,
    cursor: state.isDisabled ? 'not-allowed !important' : 'default',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? "#6BB0F5" : "",
    color: state.isFocused ? "#fff" : "#121212"
  })
});

export const SearchPermit = () => {
  const [permits, setPermits] = useState<any[]>([]);
  const { states, setStates } = useStates();
  const { cities, setCities } = useCities();
  const { setCurrentPermitForm } = useCurrentPermit();

  const [state_id, setState] = useState<number>(0)
  const [city_id, setCity] = useState<number>(0)
  const { isLoading: isStateLoading, fetchData: fetchStates } = useApi<States[]>({
    route: 'state',
    storeFunction: (data) => setStates(data)
  });
  const { isLoading: isCityLoading, fetchData: fetchCities } = useApi<Cities[]>({
    route: 'cities',
    storeFunction: data => setCities(data)
  });
  const { isLoading: isPermitLoading, fetchData: fetchPermit, data: permitRes } = useApi<any>({
    route: 'permit'
  });
  const { setCurrentFee } = useCurrentFee();

  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(cities) || isEmpty(states)) {
      fetchStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (permitRes) {
      setPermits(permitRes || [])
    }
  }, [permitRes])


  async function onSelectState(e: typeof cities[0]) {
    const { id } = e;

    const stateId = id;

    setState(+id);
    setCity(0);
    setPermits([])
    setCities([])


    if (+stateId !== 0) {
      await fetchCities(`?state_id=${stateId}`);
    } else {
      setCity(0);
      setPermits([])
    }
  }

  async function onSelectCity(e: typeof cities[0]) {
    const { id, fees: { amount } } = e;

    setCity(+id);

    setCurrentFee(amount);

    // call for a permit here
    if (id !== 0) {
      await fetchPermit(`?states_id=${state_id}&cities_id=${+id}`)
    }
  }


  function goToPermitForm(permit: PermitForm) {
    return () => {
      navigate(routes.permit);


      setCurrentPermitForm(permit)
    }
  }
  return (
    <IconBox color='primarySteel' title='Search' icon={<MdSearch />}>
      <div className='p-5 text-center md:px-9 lg:px-6 px-4 py-11 md:h-[25rem] flex flex-col h-[90vh]'>
        <div className='flex items-center justify-center'>
          <h1 className='hidden md:block  font-bold text-lg text-primarySteel'>
            Search for a Permit
          </h1>
          <div className='w-2 ml-2'>
            {(isCityLoading || isStateLoading || isPermitLoading) && <div className='h-2 w-2 rounded-full bg-ready-orange animate-bounce' />}
          </div>
        </div>

        <form className='w-full flex mt-5 items-center gap-4'>
          <div className='w-full'>
            <Select
              styles={styles('100%')}
              placeholder='State'
              value={states.map(v => ({ value: v, label: v.name })).filter(v => v.value.id === state_id)}
              onChange={e => onSelectState(e?.value as any)}
              options={states.map(v => ({ value: v, label: v.name }))}
            />
          </div>

          <div className='w-full'>
            <Select
              styles={styles('100%')}
              isDisabled={state_id === 0 || isCityLoading}
              placeholder='City'
              value={cities.map(v => ({ value: v, label: v.name })).filter(v => v.value.id === city_id)}
              onChange={e => onSelectCity(e?.value as any)}
              options={cities.map(v => ({ value: v, label: v.name }))} />
          </div>
        </form>

        <div className='flex-grow flex justify-center items-center flex-col'>
          {isEmpty(permits) && (
            <span className='text-sm text-grayScaleGrayMedDark font-medium my-auto'>
              Select a state you need a permit from.
            </span>
          )}
          {!isEmpty(permits) && <span className='w-full border-b-2 border-primarySteel my-2 mt-3 '></span>}
          {!isEmpty(permits) && <ul className='w-full overflow-y-auto flex-grow'>
            {
              permits.map((p) => {
                return <li className='bg-white rounded-lg w-full my-2 mb-3 text-left text-grayScaleGrayDark shadow-md select-none'>
                  <button className='p-2 px-4 text-left w-full' onClick={goToPermitForm(p)}>
                    <h3 className='font-bold'>{p.name}</h3>
                    <p className='text-sm text-grayScaleGrayMedDark'>{p.description || 'Some desc'}</p>
                  </button>
                </li>
              })
            }
          </ul>}
        </div>
      </div>
    </IconBox>
  );
};
