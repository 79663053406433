/* eslint-disable react-hooks/exhaustive-deps */
import { ActionTable } from "components/ActionTable"
import { useEffect, useState } from 'react';
import { useUser, useCurrentApplicant, useCurrentApplicants, useApplicants } from 'store';
import { CityApplicant, Paginated } from 'store/Entities';
import { useProtectedApi } from 'hooks/useProtectedApi';
import moment from "moment";

export const AdminApplicants = () => {
    const { setApplicants, applicants } = useApplicants();
    const { fetchData, isLoading, data } = useProtectedApi<Paginated<CityApplicant>>({
        route: 'applicants',
        storeFunction: ({ data }) => setApplicants(data)
    });
    const [page, setPage] = useState(1);
    const { setCurrentApplicant } = useCurrentApplicant();
    const { setCurrentApplicants } = useCurrentApplicants();
    const onNext = () => setPage(prev => prev + 1);
    const onPrev = () => setPage(prev => {
        if (prev > 0) return prev - 1
        return prev
    });
    async function onRefresh() {
        return await fetchData(`?paginate=true&page=${page}`);
    }

    const { user } = useUser();

    useEffect(() => {
        fetchData(`?paginate=true&page=${page}`);
    }, [page, user]);

    function selectCurrent() {
        return (_: CityApplicant | undefined, list: CityApplicant[]) => {
            if (list.length === 1) {
                const [currentApplicant] = list;
                setCurrentApplicant(currentApplicant);
                setCurrentApplicants([])
                return
            } else if (list.length > 1) {
                const listOfApplicants = list.map(l => l.id);
                setCurrentApplicants(listOfApplicants)
                return
            }
            setCurrentApplicants([])
            setCurrentApplicant(new CityApplicant())
        }
    }

    function selectAll() {
        return (selected: string[]) => {
            setCurrentApplicant(new CityApplicant());
            setCurrentApplicants(selected.map(s => +s))
        }
    }
    return (
        <div className="w-full">
            {/* applicants */}
            <div className="w-full">
                {/* list */}
                <ActionTable<CityApplicant>
                    actions={{
                        onNext,
                        onPrev,
                        onRefresh,
                        isPrevDisabled: data?.prev_page_url === null,
                        isNextDisabled: data?.next_page_url === null,
                        totals: data?.total || 0,
                        from: data?.from || 0,
                        to: data?.to || 0
                    }}
                    isLoading={isLoading}
                    onRowClick={selectCurrent()}
                    onSelectAllClick={selectAll()}
                    data={applicants}
                    cols={[
                        {
                            name: 'PermitType',
                            field: 'solicitor_id',
                            render: ({ data }) => {
                                return <div>{data.permit.name}</div>
                            }
                        },
                        {
                            name: 'Location',
                            field: 'location',
                            render: ({ data }) => {
                                return <div>{data.form.address.city},{data.form.address.state}</div>
                            }
                        },
                        {
                            name: 'Applicant',
                            field: 'name',
                            render: ({ data }) => {
                                return <div>{data.form.personalInfo.first_name} {data.form.personalInfo.last_name}</div>
                            }
                        },
                        {
                            name: 'Status',
                            field: 'status',
                            class: '',
                            render: ({ data }) => {
                                return <>{data.status.name}</>
                            }
                        },
                        {
                            name: 'Application date',
                            field: 'created_at',
                            render: ({ data }) => {
                                return <>{moment(data.created_at).format('YYYY-MM-DD')}</>
                            }
                        }]}
                />
            </div>
        </div>
    )
}

