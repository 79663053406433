import { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useApi } from 'hooks/useApi';
import { isEmpty } from 'lodash';
import { NoResult } from 'components/NoResult';
import { useStates, useCities,useCurrentPermit, useCitizenDashboardStep, useCurrentFee  } from 'store';

const styles = (width: string) => ({
  control: (styles: any, state: any) => ({
    ...styles,
    border: '1px solid #ddd',
    boxShadow: '0 !important',
    outline: '0',
    borderRadius: 8,
    width,
    opacity: state.isDisabled ? 0.95 : 1,
    cursor: state.isDisabled ? 'not-allowed !important' : 'default',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? "#6BB0F5" : "",
    color: state.isFocused ? "#fff" : "#121212"
  })
});
export const ReadySearchPermit = () => {
  const [permits, setPermits] = useState<any[]>([]);
  const { states, setStates } = useStates();
  const { cities, setCities } = useCities();
  const { setCurrentPermitForm } = useCurrentPermit();
  const { setCitizenDashboardStep } = useCitizenDashboardStep();
  const { setCurrentFee } = useCurrentFee();

  const [state_id, setState] = useState<number>(0)
  const [city_id, setCity] = useState<number>(0)
  const {isLoading: isStateLoading, fetchData: fetchStates} = useApi<States[]>({
    route: 'state',
    storeFunction: (data) => setStates(data)
  });
  const {isLoading: isCityLoading, fetchData: fetchCities} = useApi<Cities[]>({
    route: 'cities',
    storeFunction: data => setCities(data)
  });
  const {isLoading: isPermitLoading, fetchData: fetchPermit, data: permitRes} = useApi<any>({
    route: 'permit'
  });

  const fetchAll = useCallback(async () => await fetchStates(), [fetchStates])

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permitRes) {
      setPermits(permitRes || [])
    }
  }, [permitRes])


  async function onSelectState(e: typeof states[0]) {
    const { id } = e;

    const stateId = id;

    setState(+id);
    setPermits([])
    setCities([])

    if (+stateId !== 0) {
      await fetchCities(`?state_id=${stateId}`);
    } else {
      setCity(0);
      setPermits([])
    }
  }

  async function onSelectCity(e: typeof cities[0]) {
    const { id, fees: { amount } } = e;

    setCity(+id);

    setCurrentFee(amount);

    // call for a permit here
    if (id) {
      await fetchPermit(`?states_id=${state_id}&cities_id=${id}`)
    }
  }


  function goToPermitForm(permit: any) {
    return () => {
      setCitizenDashboardStep(1);

      setCurrentPermitForm(permit);
    }
  }
  return (
    <div className='h-full lg:ml-[11rem]'>
      <div className='mb-2'>
        <div className='flex items-center justify-center md:justify-start'>
          <h1 className='text-primarySteel font-bold md:mb-4 font-sans flex'>Search &nbsp; <span className='hidden md:block'>for Permits</span></h1>
          <div className='w-2  h-2 ml-2'>
            {(isCityLoading || isStateLoading || isPermitLoading) && <div className='h-2 w-2 rounded-full bg-ready-orange animate-bounce' />}
          </div>
        </div>
        <hr className='block md:hidden w-full border-b-2 border-primarySteel my-2 mt-3 '></hr>

        <p className='text-primarySteel font-sans hidden md:block'>
          Use the controls below to search for permits you'd like to apply for.
          <br />
          Select a state, then a city, and then the permit your are interested in.
        </p>
      </div>



      <form className='w-full flex mt-5 items-center gap-4'>
          <div className='w-full'>
            <Select
              styles={styles('100%')}
              placeholder='State'
              value={states.map(v => ({ value: v, label: v.name })).filter(v => v.value.id === state_id)}
              onChange={e => onSelectState(e?.value as any)}
              options={states.map(v => ({ value: v, label: v.name }))}
            />
          </div>

          <div className='w-full'>
            <Select
              styles={styles('100%')}
              isDisabled={state_id === 0 || isCityLoading}
              placeholder='City'
              value={cities.map(v => ({ value: v, label: v.name })).filter(v => v.value.id === city_id)}
              onChange={e => onSelectCity(e?.value as any)}
              options={cities.map(v => ({ value: v, label: v.name }))} />
          </div>
        </form>

        <hr className='hidden md:block w-full border-b-2 border-primarySteel my-2 mt-3 '></hr>
      <div className='flex-grow flex justify-center items-center flex-col'>
        {!isEmpty(permits) && <ul className='w-full overflow-y-auto flex-grow pb-11 px-2'>
          {
            permits.map((p) => {
              return <li key={p.id} className='bg-white rounded-lg w-full my-2 mb-3 text-left text-grayScaleGrayMedDark shadow-lg select-none'>
                <button className='p-3 px-4 text-left w-full' onClick={goToPermitForm(p)}>
                  <h3 className='font-bold font-sans mb-2'>{p.name}</h3>
                  <p className='text-xs text-grayScaleGrayMedDark font-sans'>{p.description || 'Permit for knocking doors within the city. Required for door-to-dor sales, missionary work, or other activities that involve solicitation.'}</p>
                </button>
              </li>
            })
          }
        </ul>}

        {
          isEmpty(permits) && (
            <NoResult message='Your new journey begins now! search for your permits!.' />
          )
        }
      </div>
    </div>
  )
}
