import { UnprotectedServices } from "api/unprotected"
import { get } from "lodash";
import { useState } from "react";
import { useStore } from "store";

export function useAuthApi() {
    const api = new UnprotectedServices('signIn');
    const apiSignUp = new UnprotectedServices('signUp');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError | undefined>();
    const setAuth = useStore(state => state.setAuth);
    const [success, setSuccess] = useState<'ok' | 'idle'>('idle');


    async function apiAction<P>(p: P) {
        setIsLoading(true);
        const res = await api.signIn(p);

        if (get(res, 'access_token', false) || false) {
            setIsLoading(false);
            setAuth(true)

            // eslint-disable-next-line no-restricted-globals
            location.reload()
            
            return
        }

        setIsLoading(false);
        setError(get(res, 'response.data'));
        
    }
    async function signUp<P>(payload:P) {
        setIsLoading(true);
        const res = await apiSignUp.post(payload);

        if (get(res, 'response.data.message') || false) {
            setIsLoading(false);

            return setError(get(res, 'response.data'));
        }

        setIsLoading(false);
        setError(undefined)
        setSuccess('ok')
    }
    return {
        apiAction, isLoading, error, signUp, success
    }
}