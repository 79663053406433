import { MdDone } from 'react-icons/md';
import { ReadyButton } from 'components/button/index';
import { ReadyInput } from 'components/input';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthApi } from 'hooks/useAuthApi';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';


const signUpSchema = Yup.object({
    email: Yup.string().email('This is not a valid email').required('This field must no be empty!.'),
    firstName: Yup.string().required('This field must not be empty!'),
    lastName: Yup.string().required('This field must not be empty!.'),
    password: Yup.string().min(6, 'We suggest to use a password at least 6 characters long.').required('This field must not be empty!.'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('This field must not be empty!.')
}).required()


export const SignUp = () => {
    const { handleSubmit, register, formState: { errors, isDirty } } = useForm<SignUp>({
        resolver: yupResolver(signUpSchema)
    });
    const { signUp, isLoading, error, success } = useAuthApi();

    const onSubmitUserInput = handleSubmit(async (data) => {

        const payload = {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
            first_name: data.firstName,
            last_name: data.lastName
        }

        await signUp(payload);

    })

    return (
        <>
            <div className='text-center md:px-9 lg:px-6 px-4 py-9 my-6 rounded-xl border-2 border-primarySteel'>
                {success === 'ok' && (
                    <div className='w-full'>
                        <div className='bg-primarySuccess/30 text-primarySteel rounded-md p-4 flex items-center mb-6'>
                            <div className='p-1 rounded-full bg-green-700/30 mr-3'>
                                <MdDone className='text-white ' />
                            </div>
                            Your new account has been created.
                        </div>
                        <Link to={routes.home}>
                            <ReadyButton>
                                Go back to login
                            </ReadyButton>
                        </Link>
                    </div>
                )}

                {success === 'idle' && <form onSubmit={onSubmitUserInput}>
                    <div className='text-primaryFailure font-sans text-[11px] font-semibold w-full text-center mb-1'>
                        {get(error, 'message') || null}
                    </div>
                    <div className='flex flex-col lg:justify-between w-full lg:items-center lg:flex-row'>
                        <ReadyInput {...register('firstName')} error={errors.firstName?.message} wrapperClass='w-full lg:w-[15rem]' placeholder='First Name' />
                        <ReadyInput {...register('lastName')} error={errors.lastName?.message} wrapperClass='w-full lg:w-[15rem]' placeholder='Last Name' />
                    </div>
                    <ReadyInput {...register('email')} error={errors.email?.message} wrapperClass='mb-2' placeholder='Email' />
                    <ReadyInput type='password' {...register('password')} error={errors.password?.message} wrapperClass='mb-2' placeholder='Password' />
                    <ReadyInput type='password' {...register('password_confirmation')} error={errors.password_confirmation?.message} wrapperClass='mb-2' placeholder='Confirm Password' />

                    <div className='max-w-[10rem] mx-auto mt-5'>
                        <ReadyButton loading={isLoading}>
                            Create Account
                        </ReadyButton>
                    </div>
                </form>}
            </div>
            {!isDirty &&
                <div className='w-full text-center'>
                <Link to={routes.home}>
                    <button className='font-bold border-transparent mt-4 text-primarySteel duration-300 hover:border-primarySteel border-b'>No thanks, take me to the home page</button>
                </Link>
                </div>
            }
        </>
    )
}
