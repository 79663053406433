import { api, allRoutes, RPNameSpaces } from "api/api.config";
import toast from "react-hot-toast";
export class Service implements IService {
    constructor(private apiRoute: keyof typeof allRoutes ) {}
    
    private destroySession(e: any): void {
        if (e.response && e.response.status === 401) {
            
            localStorage.removeItem(RPNameSpaces.token);
            localStorage.removeItem(RPNameSpaces.isAuth);
            
            window.location.replace('/');
            window.location.reload()
       
        }
    }

    public async get<R>(query: string = ''): Promise<R | IError> {
        try {
            const res = await api.get<R>(`${allRoutes[this.apiRoute]}${query}`);


            return res.data as R

        } catch (error) {
            const e = error as IError;
            
            toast.error("We couldn't get the information")

            this.destroySession(e);

            return e;
        }
    }
    public async post<T, R>(payload: T, query: string = ''): Promise<R | IError> {
        try {
            const res = await api.post<R>(`${allRoutes[this.apiRoute]}${query}`, payload);

            toast.success('success!')

            return res.data
        } catch (error) {
            const e = error as IError;

            toast.error("Something went wrong trying to save this!.")

            this.destroySession(e);

            return e;
        }
    }
    public async update<T, R>(id: string | number, payload: T): Promise<R | IError> {
        try {
            const res = await api.put<R>(`${allRoutes[this.apiRoute]}/${id}`, payload);

            toast.success('Up to date!')

            return res.data
        } catch (error) {
            const e = error as IError;

            toast.error("Something went wrong trying to update this!.")

            this.destroySession(e);

            return e;
        }
    }
    public async delete<R>(id: string | number): Promise<R | IError> {
        try {
            const res = await api.delete<R>(`${allRoutes[this.apiRoute]}/${id}`);

            toast.success('This data is no longer here!');

            return res.data
        } catch (error) {
            const e = error as IError;

            toast.error("Something went wrong trying to delete this!.")
            
            this.destroySession(e);

            return e;
        }
    }    
}