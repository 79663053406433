import { PermitForm } from 'components/PermitForm';
import { Checkout } from 'components/PermitForm/checkout';
import {  useCurrentPermit } from 'store';
import { useCheckoutStep } from 'store';
import { formatter } from 'utils/currency/formatter';
import { useEffect } from 'react';
import { LoadingPage } from 'pages/LoadPage';
import { usePreventReload } from 'hooks/usePreventReload';
import { MdArrowBack } from 'react-icons/md';
import { useCitizenDashboardStep } from '../../../store/index';


function getMessage(type: number): JSX.Element {
    if (type === 0) return <>This permit requires you to submit to a background check. Fill the form below and click <b>submit</b>. Depending on the background check results, you should know the result of your application 2-5 business days.</>

    return <>This Permit has an application fee. Once you submit your payment, we'll start your background check immediately</>
}

export const CitizenPermitPage = () => {
    const { checkoutStep, setCheckoutStep } = useCheckoutStep();
    const { currentPermitForm } = useCurrentPermit();
    const { setCitizenDashboardStep } = useCitizenDashboardStep();
    const [currentCity] = currentPermitForm.cities;
    // const [fee] = currentPermitForm.fees || [0];
    const currentFee = currentCity?.fees?.amount || 0
    
    usePreventReload();

    useEffect(() => {
        if (!currentPermitForm.id) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPermitForm.id])

    if (currentPermitForm.id === 0) return <LoadingPage />

    function back(step: number) {
        return () => {
            if (checkoutStep === 1) {
                return setCheckoutStep(0);
            }

            if (checkoutStep === 0) {
                setCitizenDashboardStep(step)
            }
        }
    }

    return (
        <div className='flex items-start justify-center w-full'>
            <div className='container mx-auto max-w-2xl'>
                <button className='rounded-full py-2 px-2' onClick={back(0)}>
                    <MdArrowBack className='text-2xl text-primarySteel' />
                </button>
                <div className='flex flex-col items-center container mx-auto pb-10'>
                    <div className='text-left'>
                        <h1 className='font-bold text-primarySteel'>New Applicant</h1>
                        <h4 className='text-grayScaleGrayMedDark font-bold'>{currentCity?.name},{currentPermitForm.state.name} - {currentPermitForm.name}</h4>
                        <p className='text-base mt-3 text-grayScaleGrayMedDark'>{getMessage(checkoutStep)}</p>
                    </div>
                    {checkoutStep === 1 && (
                        <div className='w-fit mt-5'>
                            <h5 className='text-grayScaleGrayDark/50 font-bold'>Fee</h5>
                            <h1 className='text-primarySteel'>{formatter(currentFee)}</h1>
                        </div>
                    )}
                    {
                        checkoutStep === 0 && <PermitForm isLoggedIn />
                    }
                    {
                        checkoutStep === 1 && <Checkout isLoggedIn />
                    }
                </div>
            </div>
        </div>
    )
}
