import { isEmpty } from 'lodash';
import { HTMLAttributes, useState } from 'react';
import { MdArrowBack, MdArrowDropDown, MdArrowForward, MdRefresh } from 'react-icons/md';
import { Loader } from 'components/Loader';

type Col<T> = {
    name: string,
    field: string,
    class?: HTMLAttributes<any>['className']
    render?: (row: Col<T> & { data: T }) => JSX.Element | null
}

type Props<T> = {
    data: Array<T & {id: number | string}>,
    cols: Array<Col<T>>,
    onRowClick?: (data: T | undefined, selectedList: T[]) => void;
    onSelectAllClick?: (selected: string[]) => void;
    isLoading?: boolean,
    hideHeader?: boolean, 
    actions?: {
        onNext: () => void,
        onPrev: () => void,
        onRefresh: () => void,
        isNextDisabled?: boolean,
        isPrevDisabled?: boolean,
        totals: number,
        from: number,
        to: number,
    }
}

export const ActionTable = function <T>({ data, cols, onRowClick, ...props }: Props<T>) {
    const [check, setCheck] = useState<Array<string>>([]);
    const [datas, setData] = useState<(T & {id: number | string})[]>([]);
    const alreadyIn = (id: string) => check.includes(id);


    function onClick(data: T & {id: number | string }) {
        return () => {
            if (!onRowClick) return;
            const id = String(data.id);
            const values = [...datas];

            if (alreadyIn(id)) {
                const vals = values.filter(d => d.id !== +id);

                onRowClick && onRowClick(undefined, vals)

                setData(vals)

                return setCheck(prev => prev.filter(i => i !== id))
            }
            values.push(data);

            setCheck(prev => ([...prev, id]));

            setData(values)

            onRowClick && onRowClick(data, values)
        }
    }

    function onSelectAll() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;
            setData([])
            if (checked) {
                const selected = data.map((d: any) => d.id.toString()) as string[];
                props.onSelectAllClick && props.onSelectAllClick(selected);
                return setCheck(selected)
            }
            props.onSelectAllClick && props.onSelectAllClick([])
            setCheck([])
        }
    }


    return (
        <div className="table-auto cursor-default w-full">
            {!props.hideHeader && <div className='h-[3rem] w-full flex items-center justify-between border-b-2 border-white md:border-0'>
                <div className='flex items-center justify-center px-5'>
                    <label className='flex items-center justify-center'>
                        <input type="checkbox" onChange={onSelectAll()} checked={!isEmpty(check)} />
                        <MdArrowDropDown />
                        {!isEmpty(check) && <> <h5 className='ml-2 font-bold text-primaryBlue'>{check.length} selected</h5>
                            <div className='h-5 border-l-2 border-primarySteel mx-2'></div>
                        </>
                        }
                    </label>
                    <button className='text-primarySteel active:opacity-60' onClick={props?.actions?.onRefresh}>
                        <MdRefresh className='text-2xl' />
                    </button>
                </div>
                <div className='flex items-center text-primarySteel'>
                    <button className='' disabled={props?.actions?.isPrevDisabled} onClick={props?.actions?.onPrev}>
                        <MdArrowBack className='text-2xl' />
                    </button>
                    <h6 className='mx-2 font-bold'>
                        {props?.actions?.from}-{props?.actions?.to} of {props?.actions?.totals}
                    </h6>
                    <button disabled={props?.actions?.isNextDisabled} onClick={props?.actions?.onNext}>
                        <MdArrowForward className='text-2xl' />
                    </button>
                </div>
            </div>}
            <div className="rounded-md overflow-hidden bg-primarySteel md:flex h-[3rem] hidden">
                <div className={`${props.hideHeader ? '' : 'px-4'} py-2 rounded-l-md flex items-center`} />
                {
                    cols.map((t, i) => {
                        return <button className={`${t.class || 'w-[20%]'} last:rounded-r-md flex items-center justify-start p-2 border-2 border-primarySteel`} key={i + t.name}>
                            <h6 className="text-white font-bold">{t.name}</h6>
                        </button>
                    })
                }
            </div>
            <div className="select-none">
                {!props.isLoading &&
                    data.map((d, i) => {
                        return <button onClick={onClick(d)}
                            className={`${alreadyIn(`${(d as any).id}`) ? 'mt-1 bg-primaryBlue rounded-md text-white' : 'text-grayScaleGrayMedDark hover:bg-grayScaleGrayMedDark/20'} w-full outline-none h-fit md:h-[3rem]  duration-300 flex flex-col md:flex-row item-center border-b-2 border-grayScaleGrayMedDark/10`}
                            key={i}
                        >
                            {!props.hideHeader && <div className="h-full flex items-center justify-center px-4 py-2">
                                <input type='checkbox' checked={alreadyIn(`${(d as any).id}`)} />
                            </div>}
                            {
                                cols.map((t, i) => {
                                    return <div key={t.field + i} className={`${t.class || 'md:w-[20%]'} p-2  h-full flex md:items-center text-left text-xs font-semibold flex-col md:flex-row`}>
                                        <h5 className='font-bold md:hidden'>{t.name}</h5>
                                        {t.render ? t.render({ ...t, data: d }) : <>{(d as any)[t.field]}</>}
                                    </div>
                                })
                            }
                        </button>
                    })
                }
                {props.isLoading && (
                    <div className='p-4 bg-grayScaleGrayDark/20 h-fit rounded-lg flex items-center justify-center'>
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    )
}
