import { UnprotectedServices } from "api/unprotected"
import { get } from "lodash";
import { useState } from "react";

export const useResetPassword = () => {
    const api = new UnprotectedServices('forgotPassword');
    const reset = new UnprotectedServices('resetPassword');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError | undefined>();
    const [success, setSuccess] = useState<'ok' | 'idle'>('idle');

    async function apiAction<P>(p: P) {
        setIsLoading(true);
        const res = await api.signIn(p);

        if (get(res, 'response.data.message') || false) {
            setIsLoading(false);

            return setError(get(res, 'response.data'));
        }

        setIsLoading(false);
        setError(undefined);

        setSuccess('ok')
        
    }

    async function changePassword(payload: ResetPassword) {
        setIsLoading(true);

        const res = await reset.resetPassword(payload);

        if (get(res, 'response.data.message') || false) {
            setIsLoading(false);

            return setError(get(res, 'response.data'));
        }

        setIsLoading(false);
        setError(undefined);

        setSuccess('ok')
    }

    return {
        apiAction, isLoading, error, success, changePassword
    }
}
