import { FC } from "react"
import { MdInfo } from "react-icons/md"


type Props = {
    message?: string
}

export const NoResult: FC<Props> = ({ message = '' }) => {
  return (
    <div className="flex items-center justify-center h-full py-10 flex-col">
      <div className="rounded-full bg-ready-orange/20 mr-2 flex items-center justify-center animate-bounce">
        <MdInfo className="text-ready-orange m-1 text-3xl"/>
      </div>
        <h5 className="font-sans font-bold text-primarySteel/40 ">{message}</h5>
    </div>
  )
}
