import { Link } from "react-router-dom";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// components
import { ReadyButton } from "components/button";
import { IconBox } from "components/IconBox";
import { ReadyInput } from "components/input";
import { MdPerson } from "react-icons/md";
import { useAuthApi } from "hooks/useAuthApi";
import { routes } from "utils/routes";


const loginSchema = Yup.object({
  email: Yup.string().email('This is not a valid email').required('This field must not be empty'),
  password: Yup.string().required('this field must not be empty')
}).required(); 


export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SingIn>({
    resolver: yupResolver(loginSchema),
  });
  const { isLoading, apiAction, error } = useAuthApi();

  const onSubmit = handleSubmit(async(data) => {
    await apiAction(data);
  })


  return (
    <IconBox color='ready-orange' title='Login' icon={<MdPerson />}>
      <div className='text-center md:px-9 lg:px-6 px-4 py-10 h-[25rem]'>
        <h1 className='hidden md:block capitalize font-bold text-lg text-ready-orange'>
          Login
        </h1>
        {error && (
          <h6 className='text-primaryFailure uppercase font-bold font-sans text-xs'>
            your email or password are incorrect
          </h6>
        )}
        <form onSubmit={onSubmit} className='flex flex-col items-center mt-5'>
          <div className='w-full'>
            <ReadyInput
              {...register('email')}
              error={errors.email?.message}
              placeholder='username'
            />
            <ReadyInput
              {...register('password')}
              error={errors.password?.message}
              type='password'
              placeholder='password'
            />
          </div>
          <div className='w-full text-right'>
            <Link to='/reset-password'>
              <p className='font-semibold text-primaryBlue capitalize text-sm'>
                forgot password?
              </p>
            </Link>
          </div>
          <div className='flex flex-col justify-center items-center box-border'>
            <ReadyButton type='submit' loading={isLoading}>
              Login
            </ReadyButton>
            <Link to={routes.createAccount}>
              <ReadyButton type='button'>create account</ReadyButton>
            </Link>
          </div>
        </form>
      </div>
    </IconBox>
  );
};
