import { Logo } from "components/Logo";
import { Link } from "react-router-dom";
import { routes } from 'utils/routes/index';

export const ReadyHeader = () => {
  return (
    <header className='md:p-12 p-4 bg-white md:bg-transparent'>
      <nav className='container mx-auto '>
        <div className='w-full text-center'>
          <Link to={routes.home}>
            <Logo />
          </Link>
        </div>
      </nav>
    </header>
  );
}

