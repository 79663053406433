import { NotFound } from '../pages/404/index';
import { adminRoutes } from './admin';
import { citizenRoutes } from './citizen';
import { citiesRoutes } from './citiesRoutes';
export type AppRoute = {
    path: string,
    component: JSX.Element
}

export enum RolId {
    ADMIN = 1,
    CITY = 2,
    CITIZEN = 3,
    DEFAULT = 0
}

export function getRoutes(userType: RolId): Array<AppRoute> {
    switch (userType) {
        case RolId.ADMIN: {
            return adminRoutes;
        }
        case RolId.CITY: {
            return citiesRoutes;
        }
        case RolId.CITIZEN: {
            return citizenRoutes;
        }
        default: {
            return [
                { path: '*', component: <NotFound /> }
            ]
        }
    }
}

