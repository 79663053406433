export enum UserRoll {
    admin = 1,
    city = 2,
    citizen = 3
};


export function getUserAccount(userType: UserType | 0) {
    switch (userType) {
        case UserRoll.admin: {
            return 'Company Admin'
        }
        case UserRoll.city: {
            return 'City'
        }
        case UserRoll.citizen: {
            return 'Citizen'
        }
        default:
            return ' ';
    }
}