import { ReadyButton } from 'components/button';
import { MdArrowBack } from 'react-icons/md';
import { ReadyInput } from 'components/input';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLogout } from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import { useUser, usePasswordStep } from '../../store/index';
import { useProtectedApi } from '../../hooks/useProtectedApi';
import { routes } from '../../utils/routes/index';


const changePass = Yup.object({
    previous_password: Yup.string().required('This field must not be empty!.').max(6, 'Remember all password at least have 6 characters'),
}).required()
const resetPass = Yup.object({
    password: Yup.string().min(5, 'We suggest to use a password at least 6 characters long.').required('This field must not be empty!.'),
    new_password: Yup.string().min(6, 'We suggest to use a password at least 6 characters long.').required('This field must not be empty!.'),
}).required()

class Form {
    previous_password: string = ''
}

class IResetPass {
    password: string = '';
    new_password: string = '';
}

export default function ChangePassword() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<Form>({
        resolver: yupResolver(changePass)
    });
    const { postData, isLoading } = useProtectedApi({
        route: 'signIn'
    });
    const { user } = useUser();
    const { logout } = useLogout();
    const navigateTo = useNavigate();
    const { setPasswordStep, passwordStep } = usePasswordStep()
    const changeStep = (current: number) => () => setPasswordStep(current)

    const changePassword = handleSubmit(async data => {
        const [{ status }] = await Promise.allSettled([postData({
            email: user?.email,
            password: data.previous_password
        })])


        if (status === 'fulfilled') {
            setPasswordStep(3)
            reset({})
        }
    })

    const goBack = () => {
        const onCloseStep = changeStep(1);

        reset(new Form())

        onCloseStep()
    };

    const logoutUser = () => {
        logout()

        navigateTo(routes.resetPassword, {replace: true})
    }

    return (
        <div className="mt-6 flex flex-col">
            {
                passwordStep === 1 && (
                    <div className="w-fit">
                        <h4 className='text-grayScaleGrayDark/60 font-bold'>Password</h4>
                        <ReadyButton buttonMode="outline" wrapperClass="w-fit" onClick={changeStep(2)}>
                            CHANGE PASSWORD
                        </ReadyButton>
                    </div>
                )
            }
            <div className='w-full mb-[3rem]'>
                {
                    passwordStep !== 1 && (
                        <button className='flex items-center rounded-full duration-700  px-2 py-4 hover:bg-grayScaleGrayMedDark/10' onClick={goBack}>
                            <MdArrowBack className='text-xl text-grayScaleGrayMedDark mr-4' />
                            <h5 className='text-grayScaleGrayMedDark font-medium'>Change password</h5>
                        </button>
                    )
                }
                {
                    passwordStep === 2 && (
                        <><form className='mt-2 ' onSubmit={changePassword}>
                            <label className='text-md font-light text-grayScaleGrayMedDark '>
                                <h5 className='text-grayScaleGrayMedDark font-medium mb-2'>Previous password</h5>
                                <ReadyInput type='password' {...register('previous_password')} error={errors.previous_password?.message} placeholder='******' />
                            </label>
                            <button className='text-primaryBlue mb-3 w-full text-right' type='button' disabled={isLoading} onClick={logoutUser}>forgot password?</button>
                            <ReadyButton wrapperClass='py-4' loading={isLoading}>
                                LOG IN
                            </ReadyButton>
                        </form></>
                    )
                }

                {
                    passwordStep === 3 && <ResetPassword />
                }
            </div>
        </div>
    )
}


function ResetPassword() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<IResetPass>({
        resolver: yupResolver(resetPass)
    });
    const { postData, isLoading } = useProtectedApi({
        route: 'changePassword'
    });
    const { setPasswordStep } = usePasswordStep()

    const changePassword = handleSubmit(async data => {
        await postData(data);        

        reset({});

        setPasswordStep(1);
    });
    return (
        <form onSubmit={changePassword}>
            <label className='text-md font-light text-grayScaleGrayMedDark'>
                <h5 className='text-grayScaleGrayMedDark font-medium mb-2'>New password</h5>
                <ReadyInput type='password'  {...register('password')} error={errors.password?.message} />
            </label>
            <label className='text-md font-light text-grayScaleGrayMedDark'>
                <h5 className='text-grayScaleGrayMedDark font-medium mb-2'>Confirm new password</h5>
                <ReadyInput type='password' {...register('new_password')} error={errors.new_password?.message} />
            </label>
            <ReadyButton loading={isLoading}>
                SAVE NEW PASSWORD
            </ReadyButton>
        </form>
    )
}