import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { MdOutlineMarkEmailRead } from 'react-icons/md';

import { Layout } from 'layout/Layout';
import { ReadyButton } from 'components/button';
import { ReadyInput } from 'components/input';
import { Link } from 'react-router-dom';
import { useResetPassword } from 'hooks/useResetPassword';
import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
interface PasswordReset {
  email: string;
}

const schema = Yup.object({
  email: Yup.string()
    .email('this is not a valid email.')
    .required('this field must not be empty.'),
}).required();

export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordReset>({
    resolver: yupResolver(schema),
  });
  const { apiAction, error, isLoading, success } = useResetPassword();

  const submit = handleSubmit(async (data) => {
    await apiAction(data);
  });

  const query = useQuery();
const navigate = useNavigate();

  useEffect(() => {
    const token = query.get('token');
    const email = query.get('email');
    const name = query.get('full_name');
    if(token && email && name){
      navigate(`/reset-password/new-password?token=${query.get('token')}&email=${email}&full_name=${name}`, { replace: true });
    }
  }, [query, navigate])

  return (
    <Layout>
      <div className='px-4 max-w-md mx-auto flex justify-center items-center flex-col h-full font-sans'>
        {success === 'idle' && (
          <>
            <div className='w-full text-center mb-3'>
              <h1 className='text-primarySteel text-xl font-bold'>
                Resetting your Password
              </h1>
            </div>
            <p className='text-gray-400 text-sm text-justify'>
              To reset your password, enter your email below and click{' '}
              <b className='text-ready-orange'>submit</b>. We will send you an
              email with a link to reset your password. If you don't see it,
              make sure to check your spam folder.
            </p>

            <form className='px-4 my-10 w-full' onSubmit={submit}>
              <ReadyInput
                {...register('email')}
                error={errors.email?.message}
                type='email'
                placeholder='Email'
              />

              {error && (
                <h6 className='text-primaryFailure font-sans text-xs font-bold text-center w-full'>
                  {error.message}
                </h6>
              )}

              <div className='max-w-[120px] mx-auto mt-4'>
                <ReadyButton type='submit' loading={isLoading}>
                  SUBMIT
                </ReadyButton>
                <Link to='/'>
                  <ReadyButton type='submit' buttonMode='outline'>
                    Back
                  </ReadyButton>
                </Link>
              </div>
            </form>
          </>
        )}

        {success === 'ok' && (
          <div className='flex flex-col items-center justify-center p-3 rounded-md border-2 border-ready-orange'>
            <MdOutlineMarkEmailRead className='text-5xl text-ready-orange mb-4' />

            <p className='text-grayScaleGrayDark text-justify mb-6 max-w-xs mx-9'>
              An email have been sent to you, please check your inbox, and if it
              is not there check your span folder.
            </p>

            <Link to='/'>
              <ReadyButton buttonType='info' buttonMode='outline'>
                Go back
              </ReadyButton>
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};
