import { AuthLayout } from "layout/AuthLayout"
import { CityApplicantDetail } from "../Applicants/details";
import { CityApplicants } from '../Applicants/index';

export const CityDashboard = () => {
  return (
    <AuthLayout 
    
        leftPanel={
            <CityApplicants />
        }
        rightPanel={<CityApplicantDetail />}
    />
  )
}
