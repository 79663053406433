import { Logo } from "components/Logo"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"
import { useLogout } from 'hooks/useLogout';
import { ReadyButton } from 'components/button';
import { MdMenu } from "react-icons/md";
import { useUser } from 'store';
import { getUserAccount } from 'utils/getUserAccount';
import { Sidebar } from "components/Sidebar";
import { useCurrentSidebar } from 'store/';
import { SideBarOption } from '../../../store/Entities';


export const AuthHeader = () => {
  const { logout } = useLogout();
  const { user } = useUser();
  const { currentSideBar, setCurrentSidebar } = useCurrentSidebar()
  const handleSideBar = (current: SideBarOption) => () => setCurrentSidebar(current);

  return <header className='px-2 py-4 bg-white select-none font-sans shadow'>
    <Sidebar
      isOpen={currentSideBar === SideBarOption.MENU}
      onClose={handleSideBar(SideBarOption.CLOSE)}
      renderHeader={
        <Logo size="sm" />
      }
      footer={
        <div className="px-4">
          <ReadyButton buttonMode="outline" buttonType="info" onClick={logout}>
            Log out
          </ReadyButton>
        </div>
      }
    >
      <ul className="text-left">
        <li className="flex items-center justify-start py-4">
          <Link to={routes.home}>
            <button className="w-full" onClick={handleSideBar(SideBarOption.CLOSE)}>
              <h4 className="px-4 text-grayScaleGrayMedDark hover:font-bold hover:text-primarySteel">Dashboard</h4>
            </button>
          </Link>
        </li>
        <li className="flex items-center justify-start py-4">
          <Link to={routes.settings}>
            <button className="w-full" onClick={handleSideBar(SideBarOption.CLOSE)}>
              <h4 className="px-4 text-grayScaleGrayMedDark hover:font-bold hover:text-primarySteel">Settings</h4>
            </button>
          </Link>
        </li>
      </ul>
    </Sidebar>
    <nav className='w-full px-4 flex justify-between items-center'>
      <button className="lg:hidden" onClick={handleSideBar(SideBarOption.MENU)}>
        <MdMenu className="text-4xl" />
      </button>

      <div className="flex">
        <div className="user-logo-type hidden lg:block">
          <Link to={routes.home}>
            <Logo size="sm" />
          </Link>
          <p className="text-sm text-primarySteel font-sans">{getUserAccount(user?.roles_id || 0)}</p>
        </div>

        <ul className="hidden lg:flex ml-10">
          <li className="flex items-center justify-center">
            <Link to={routes.home}>
              <h4 className="px-4 text-grayScaleGrayMedDark font-bold hover:text-primarySteel duration-300 transition-all">Dashboard</h4>
            </Link>
          </li>
          <li className="flex items-center justify-center">
            <Link to={routes.settings}>
              <h4 className="px-4 text-grayScaleGrayMedDark font-bold hover:text-primarySteel duration-300 transition-all">Settings</h4>
            </Link>
          </li>
        </ul>
      </div>
      <div className="hidden lg:flex items-center flex-grow justify-end">
        <h4 className="text-primarySteel font-bold mr-4">{user?.name}</h4>
        <div className="w-[10rem]">
          <ReadyButton buttonMode="outline" buttonType="info" onClick={logout}>
            Log out
          </ReadyButton>
        </div>
      </div>
    </nav>
  </header>
}