import { Logo } from 'components/Logo/index';

export const LoadingPage = (props: {class?: string, showMessage?: boolean }) => {
    return (
        <div className={`${ props.class || 'h-screen'} flex items-center justify-center flex-col`}>
            <div className='animate-bounce p-4'>
                <Logo />
            </div>
            {props.showMessage && <div className='mt-12'>
                <h4 className='text-grayScaleGrayMedDark'>Getting things ready for you...</h4>
            </div>}
        </div>
    )
}
