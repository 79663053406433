import { FC, useState } from 'react';
import { AuthLayout } from 'layout/AuthLayout';
import { CitiesAdmin } from 'pages/Admin/Cities';
import { Tabs } from 'components/tabs';
import { AdminApplicants } from 'pages/Admin/Applicants';
import { AdminApplicantDetail } from 'pages/Admin/Applicants/Details';
import { AdminCityDetail } from '../Cities/Details';
import { MdAdd } from 'react-icons/md';
import { useSideBarDialog } from 'store';

export const AdminDashboard: FC = () => {
  const [currentPage, setSteps] = useState(0);
  const setTab = (n: number) => setSteps(n)
  const {setSidebarDialog } = useSideBarDialog();
  const Details = [<AdminApplicantDetail />, <AdminCityDetail />];

  const openCitiesDialog = () => setSidebarDialog('city');

  return (
    <AuthLayout
      leftPanel={
        <Tabs
          onChangeTab={setTab}
          actions={[
            {
              icon: <MdAdd className='text-xl'/>,
              id: 'add-cities',
              isVisible: currentPage === 1,
              onClick: openCitiesDialog,
            }
          ]}
          tabs={[
            {
              name: 'Applicants',
              component: <AdminApplicants />
            },
            {
              name: 'Cities',
              component: <CitiesAdmin />
            }
          ]}
          defaultTab={currentPage}
        />
      }
      rightPanel={
        <>
          {Details[currentPage]}
        </>
      }
    />
  )
}
