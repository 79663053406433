import { routes } from 'utils/routes/index';
import { AppRoute } from 'appRoutes/getRoutes';
import { CityDashboard } from 'pages/City/Dashboard';
import { CitySettings } from '../pages/City/Settings/index';


export const citiesRoutes: Array<AppRoute> = [
    {
        path: routes.home,
        component: <CityDashboard />,
    },
    {
        path: routes.settings,
        component: <CitySettings />
    }
]