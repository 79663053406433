import { AuthLayout } from "layout/AuthLayout"
import { useUser } from 'store';
import { getUserAccount } from "utils/getUserAccount";
// import { CitizenCurrentAddress } from './CurrentAddress/index';
import ReactTooltip from "react-tooltip";
import ChangePassword from '../../../components/ChangePassword/index';
const tip = 'Beyond your login information, we save some of you information to make applying as easy as possible for you. Your information is not shared with this parties except our background checker and the city whose permits you apply for.'

export const CitizenSettings = () => {
    const { user } = useUser();
    return (
        <AuthLayout hideRightPanel
            leftPanel={
                <div className="h-full flex flex-col justify-start px-4 lg:px-[8rem]">
                    <div className="container mx-auto max-w-2xl">
                        <ReactTooltip type="light" place="right" effect="solid" className="tooltip" />
                        <div>
                            <h1 className="text-primarySteel font-bold text-3xl">Settings</h1>
                            <span data-tip={tip} className="text-primaryBlue border-b border-primaryBlue cursor-pointer text-sm font-medium">What do you use this information for?</span>
                        </div>

                        <div className="my-12 text-grayScaleGrayMedDark">
                            {/* account info */}
                            <h2 className="text-primarySteel font-bold">Account info</h2>
                            <div className="border-b border-primarySteel mt-2"></div>
                            <div className="w-fit">
                                <h4>Account type</h4>
                                <div className="mt-4 p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 w-[10rem] text-grayScaleGrayMedDark opacity-50">
                                    {getUserAccount(user?.roles_id || 0)}
                                </div>
                            </div>
                            <div className="max-w-sm">
                                <ChangePassword />
                            </div>
                        </div>
                        <div className="my-12">
                            {/* account address */}
                            {/* <h2 className="text-primarySteel font-bold">Current address</h2>
                            <div className="border-b border-primarySteel mt-2"></div>
                            <div className="md:pr-[10rem] max-w-[35rem]">
                                <CitizenCurrentAddress />
                            </div> */}
                        </div>
                    </div>
                </div>
            }

        />
    )
}
