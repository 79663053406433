import { Layout } from 'layout/Layout';

import { Login } from 'sections/Welcome/Login';
import { SearchPermit } from 'sections/Welcome/Search';

export const MainScreen = () => {
  return (
    <Layout>
      <div className='h-full md:flex justify-center flex-nowrap'>
        <div className='flex flex-col md:flex-row w-full'>
          <div className='w-full md:w-1/2 lg:w-4/5  order-last md:order-none md:mx-6'>
            <SearchPermit />
          </div>
          <div className='w-full md:w-1/2 lg:w-4/5 h-full md:mx-6'>
            <Login />
          </div>
        </div>
      </div>
    </Layout>
  );
};
