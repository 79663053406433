import { Tabs } from "components/tabs"
import { MyPermits } from "../MyPermits";
import { CitizenApplications } from '../MyApplications/';


export const CitizenDashboardTabs = () => {
  return (
    <Tabs
        defaultTab={1}
        tabs={[
            {
                name: 'My Permits',
                component: <MyPermits />
            },
            {
                name: 'My Applications',
                component: <CitizenApplications />
            }
        ]}
    />
  )
}
