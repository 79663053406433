/* eslint-disable react-hooks/exhaustive-deps */
import { ActionTable } from "components/ActionTable";
import { ReadyButton } from "components/button";
import { InfoBox } from "components/InfoBox"
import { ReadyInput } from "components/input"
import moment from "moment";
import { MdPerson } from "react-icons/md";
import { useCurrentCity } from 'store';
import { City, Fees } from "store/Entities";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { routes } from '../../../../utils/routes/index';
import { useSideBarDialog } from '../../../../store/index';

export const AdminCityDetail = () => {
  const { currentCity, setCurrentCity } = useCurrentCity();
  const isCurrentCity = !!currentCity.id;
  const navigate = useNavigate();
  const { setSidebarDialog } = useSideBarDialog()

  useEffect(() => {
    return () => {
      setCurrentCity(new City());
    }
  }, []);

  function goto() {
      navigate(routes.invite)
  }

  function gotoChangeFee() {
      setSidebarDialog('fee')
  }
  return (
    <div className="w-full md:w-[30rem]">
      <h1 className="text-primarySteel font-bold">City Details</h1>
      {!isCurrentCity && <p className="my-6 text-grayScaleGrayDark/60 font-bold">Select a city to know more about it.</p>}
      {isCurrentCity && (
        <>
          <form className="my-6">
            <label className="">
              <h5 className="text-grayScaleGrayMedDark">Name</h5>
              <ReadyInput value={currentCity.name} disabled/>
            </label>
            <div className="w-full my-2 flex">
              <InfoBox
                title="Join Date"
                sub={moment(currentCity.created_at).format('YYYY-MM-DD')}
              />
            </div>
            <div className="flex items-center gap-4">
              <ReadyButton type='button' buttonType="info" buttonMode="outline" onClick={gotoChangeFee}>
                 <MdPerson className="mr-2"/> Change fee
              </ReadyButton>
              <ReadyButton type='button' buttonType="info" buttonMode="outline" onClick={goto}>
                 <MdPerson className="mr-2"/> invite an user
              </ReadyButton>
            </div>
          </form>

          <div className="w-full">
            <h1 className="text-primarySteel font-bold my-6">Permit Types</h1>
            <ActionTable<Fees>
              data={currentCity.fees ? [currentCity.fees] : []}
              hideHeader
              cols={[
                {
                  name: 'Permit Name',
                  field: '',
                  class: 'w-[30%]',
                  render: ({ data }) => {
                    return <div className="">{data.name}</div>
                  }
                },
                {
                  name: 'Fee',
                  field: 'fee',
                  class: 'w-[20%]',
                  render: ({ data }) => {
                    return <div>${(data.amount)}</div>
                  }
                },
                {
                  name: 'Expiry',
                  field: '',
                  class: 'w-[50%]',
                  render: ({ data }) => {
                    return <div>{moment(data.expire_at || new Date()).format('YYYY-MM-DD')}</div>
                  }
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  )
}
