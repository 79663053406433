import { ReadyButton } from "components/button";
import { ReadyInput } from "components/input";
import { AuthLayout } from "layout/AuthLayout"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from "lodash";
import { useProtectedApi } from 'hooks/useProtectedApi';


const signUpSchema = Yup.object({
  email: Yup.string().email('This is not a valid email').required('This field must no be empty!.'),
  name: Yup.string().required('This field must not be empty!'),
  password: Yup.string().min(6, 'We suggest to use a password at least 6 characters long.').required('This field must not be empty!.'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('This field must not be empty!.')
}).required()

export const Invite = () => {
  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    resolver: yupResolver(signUpSchema)
  });
  const { postData, isLoading, } = useProtectedApi({
    route: 'invite'
  })
  const navigateTo = useNavigate();

  const userInput = handleSubmit(async  data => {
    const payload = {
      ...omit(data, ['password_confirmation']),
      roles_id: 1
    }

    await postData(payload);

    reset({ name: '', email: '', password: '', password_confirmation: '' });

    navigateTo('/', { replace: true });
  })

  return (
    <AuthLayout
      hideRightPanel
      leftPanel={<div className="container mx-auto px-6 md:px-12 max-w-2xl flex flex-col gap-6">
        <div className="">
          <div>
            <h1>Invite user</h1>
            <h5>Required fields to invite and user</h5>
          </div>
        </div>

        <form onSubmit={userInput} className='flex flex-col gap-4'>
          <div>
            <ReadyInput {...register('name')} placeholder="Name" error={errors.name?.message}/>
          </div>
          <div>
            <ReadyInput {...register('email')} placeholder="Email" type='email' error={errors.email?.message}/>
          </div>

          <div>
            <ReadyInput {...register('password')} placeholder="Password" type='password' error={errors.password?.message}/>
          </div>

          <div>
            <ReadyInput {...register('password_confirmation')} placeholder="Confirm password" type='password' error={errors.password_confirmation?.message}/>
          </div>

          <ReadyButton type="submit" loading={isLoading}> 
            Invite
          </ReadyButton>
        </form>
      </div>}

    />
  )
}
