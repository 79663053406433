export class PermitForm {
    id: number = 0;
    states_id: number = 0;
    name: string = '';
    form: Form = new Form();
    verb: string = '';
    created_at: string = '';
    updated_at: string = '';
    cities: City[] = [];
    state: State = new State();
    fees: Fee[] = [];
}

export class City {
    id: number = 0;
    states_id: number = 0;
    name: string = '';
    pivot: Pivot = new Pivot();
    created_at: string = '';
    updated_at: string = '';
    fees: Fees = new Fees(); 
}

export class Pivot {
    permits_id: number = 0;
    cities_id: number = 0;
}

export class Fee {
    id: number = 0;
    cities_id: number = 0;
    permits_id: number = 0;
    name: string = '';
    amount: number = 0;
}

export class Group {
    name: string = '';
    fields?: Field[] = [];
    field?: Field[] = [];
}

export class Field {
    type: string = '';
    name: string = '';
    label?: string = '';
}

export class State {
    id: number = 0;
    name: string = '';
}


export class PaymentPayload {
    permits_id: number = 0;
    application_date: string = '';
    user_id: string | undefined = '';
    form: Form = new Form();
}
export class Form {
    personalInfo: PersonalInfo = new PersonalInfo();
    address: Address = new Address();
}

export class Address {
    line_one: string = '';
    line_two: string | undefined;
    city: string = '';
    state: number = 0;
    zip: string = '';
}

export class PersonalInfo {
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    ssn: string = '';
    license: string | undefined = '';
    birthday?: string = '';
    race?: string = '';
}


export class CityApplicant {
    id: number = 0;
    users_id: number = 0;
    permits_id: number = 0;
    permit: PermitForm = new PermitForm();
    states_id: number = 0;
    cities_id: number = 0;
    applications_statuses_id: number = 0;
    form: Form = new Form();
    application_date: string = '';
    user: User = new User();
    status: Status = new Status();
    deleted_at: string = '';
    created_at: string = '';
    updated_at: string = '';
    background_check?: string = '';
}

export class Status {
    id: number = 0;
    name: string = '';
    deleted_at: string = '';
    created_at: string = '';
    updated_at: string = '';
}

export class User {
    id: number = 0;
    roles_id: number = 0;
    states_id: string = '';
    cities_id: string = '';
    zip: string = '';
    address2: string = '';
    address1: string = '';
    name: string = '';
    email: string = '';
    created_at: string = '';
    updated_at: string = '';
    stripe_id: string = '';
    full_name: string = '';
}


// Generated by https://quicktype.io

export class Paginated<T> {
    current_page:   number = 0;
    data:           T[] = [];
    first_page_url: string = '';
    from:           number = 0;
    last_page:      number = 0;
    last_page_url:  string = '';
    links:          Link[] = [];
    next_page_url:  string = '';
    path:           string = '';
    per_page:       number = 0;
    prev_page_url:  string = '';
    to:             number = 0;
    total:          number = 0;
}
export class Link {
    url: string = '';
    label:  string = '';
    active: boolean = false;
}

export type Menus = 'City' | 'Menu' | 'Close'
export enum SideBarOption {
    CITY = 'City',
    MENU = 'Menu',
    CLOSE = 'Close'
} 

// Generated by https://quicktype.io

export class Fees {
    id:         number = 0;
    cities_id:  number = 0;
    name:       string = '';
    amount:     number = 0;
    expire_at:  string = '';
}


