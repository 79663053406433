/* eslint-disable react-hooks/exhaustive-deps */
import { useProtectedApi } from 'hooks/useProtectedApi';
import { City, Paginated } from 'store/Entities';
import { useCities, useCurrentCity } from 'store';
import { useEffect, useState } from 'react';
import { ActionTable } from 'components/ActionTable';
import moment from 'moment';
import { AdminNewCity } from './CityCrud';
import { AdminCityChangeFee } from './CityCrud/ChangeFee/index';

export const CitiesAdmin = () => {
    const { setCities, cities } = useCities();
    const { isLoading, fetchData, data } = useProtectedApi<Paginated<City>>({
        route: 'cities',
        storeFunction: ({ data }) => setCities(data as any),
    });
    const { setCurrentCity } = useCurrentCity();
    const [page, setPage] = useState(1);
    const onNext = () => setPage(prev => prev + 1);
    const onPrev = () => setPage(prev => {
        if (prev > 0) return prev - 1
        return prev
    });

    useEffect(() => {
        fetchData(`?paginate=true&page=${page}`)
    }, [])

    async function onRefresh() {
        await fetchData(`?paginate=true&page=${page}`)
    }

    function selectCurrent() {
        return (_: City | undefined, list: City[]) => {
            if (list.length === 1) {
                const [ currentApplicant ] = list;
                setCurrentCity(currentApplicant);
                return
            }

            setCurrentCity(new City());
        }
    }

    function selectAll() {
        return (selected: string[]) => {
            
        }
    }

    return (
        <div>
            <AdminNewCity />
            <AdminCityChangeFee />
            <ActionTable<City>
               actions={{
                onNext,
                onPrev,
                onRefresh,
                isPrevDisabled: data?.prev_page_url === null,
                isNextDisabled: data?.next_page_url === null,
                totals: data?.total || 0,
                from: data?.from || 0,
                to: data?.to || 0
            }}
                data={cities as any}
                onRowClick={selectCurrent()}
                onSelectAllClick={selectAll}
                cols={[
                    {
                     name: 'City',
                     field: 'name'
                    },
                    {
                        name: 'Join Date',
                        field: 'created_at',
                        render: ({ data }) => {
                            return <div>{moment(data.created_at).format('YYYY-MM-DD')}</div>
                        }
                    }
                ]}
                isLoading={isLoading}
            />
        </div>
    )
}
