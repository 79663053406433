import { FC, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  color: string;
  title: string;
}

export const IconBox: FC<Props> = ({ children, icon, color, title }) => {
  function colorHelper(color: string, type: 'border' | 'text') {
    return `${type}-${color}`;
  }
  return (
    <div className='my-5 border-ready-orange rounded-lg'>
      <div className='block md:hidden w-full text-center py-2'>
        <h4
          className={`${colorHelper(
            color,
            'text'
          )} capitalize font-bold text-xl`}>
          {title}
        </h4>
      </div>
      <div
        className={`border-4 md:border-4 ${colorHelper(color, 'border' )} 
         border-x-0 border-b-0 md:border-b-4 md:border-x-4  md:rounded-xl relative box-border w-full h-full`}>
        <div
          data-test='icon-render'
          className={`flex items-center justify-center absolute left-0 -top-6 w-full z-10 ${colorHelper(
            color,
            'text'
          )} `}>
          <span className='bg-grayScaleGray hidden md:block text-5xl'>{icon}</span>
        </div>

        <div data-test='children py-4'>{children}</div>
      </div>
    </div>
  );
};
