/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useProtectedApi } from 'hooks/useProtectedApi';
import { CityApplicant } from '../../../../store/Entities';
import moment from 'moment';
import { ActionTable } from '../../../../components/ActionTable/index';

export const CitizenApplications = () => {
  const { fetchData, isLoading, data } = useProtectedApi<CityApplicant[]>({
    route: 'myApplicants'
  });

  useEffect(() => {
    fetchData();
  }, [])

  return (<div className='w-full lg:w-[35rem]'>
    <ActionTable<CityApplicant>
      hideHeader
      isLoading={isLoading}
      data={data?.filter(d => d.status.name !== 'Accepted') || []} cols={[
        {
          name: 'PermitType',
          class: 'w-[30%]',
          field: 'permit.name',
          render: ({ data }) => {
            return <>{data.permit.name}</>
          }
        },
        {
          name: 'Location',
          field: 'location',
          render: ({ data }) => {
            return <>{data.form.address.state}, {data.form.address.city}</>
          }
        },
        {
          name: 'Status',
          field: 'status.name',
          render: ({ data }) => {
            return <>{data.status.name}</>
          }
        },
        {
          name: 'Application Date',
          field: 'status.name',
          render: ({ data }) => {
            return <>{moment(data.created_at).format('YYYY/MM/DD')}</>
          }
        }
      ]} />
  </div>

  )
}
