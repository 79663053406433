export const styles = (width: string) => ({
    control: (styles: any, state: any) => ({
        ...styles,
        border: '1px solid #ddd',
        boxShadow: '0 !important',
        outline: '0',
        borderRadius: 8,
        width,
        opacity: state.isDisabled ? 0.95 : 1,
        cursor: state.isDisabled ? 'not-allowed !important' : 'default',
    }),
    option: (base: any, state: any) => ({
        ...base,
        backgroundColor: state.isFocused ? "#6BB0F5" : "",
        color: state.isFocused ? "#fff" : "#121212"
    })
});