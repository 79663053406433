import { SignUp } from 'components/SignUp';
import { Layout } from 'layout/Layout';
import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AfterPay = () => {
  const active = useQuery().get('active');
  const navigate = useNavigate();


  useEffect(() => {
    if (active !== 'true') {
      navigate('/', {replace: true})
    }
  }, [active, navigate])
  return (
    <Layout>
      <div className='w-full lg:w-1/2 flex flex-col  justify-center container mx-auto text-left px-4'>
        <h1 className='text-primarySteel font-bold'>Application submitted!</h1>
        <p className='text-grayScaleGrayMedDark'>We will follow up with you via the email you provide. To make your next application go easier and to keep track of all your applications
          in one easy place, create an account with use - it's totally free.
        </p>
        {/* form */}
        <SignUp />
      </div>
    </Layout>
  )
}
