import { FC } from 'react';


interface Props {
  size?: 'sm' | 'md' | 'lg'
}

export const Logo: FC<Props> = ({ size }) => {

    function getSize(size: Props['size']) {
      switch (size) {
        case 'sm':
          return ''
        case 'md': 
          return ''
        case 'lg': 
          return ''
        default:
          return 'text-2xl md:text-4xl';
      }
    }

    return (
      <h1 className={`${getSize(size)} text-primarySteel select-none`}>
        <span className='italic font-light'>Ready</span>
        <span className='text-ready-orange font-bold normal-case'>
          Permit
        </span>
      </h1>
    );
}
