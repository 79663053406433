import { ReadyButton } from 'components/button';
import { useCheckoutStep, useCurrentPermit, useAnonymousToken, usePaymentPayload, useCurrentFee } from 'store';
import axios from 'axios';
import { allRoutes, API_URL } from 'api/api.config';

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { get, omit } from 'lodash';
import { LoadingPage } from 'pages/LoadPage';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Service } from 'api/protected';
import { useCitizenDashboardStep } from 'store/index';
import { PermitForm } from 'store/Entities';
import { routes } from 'utils/routes';
import { useProtectedApi } from 'hooks/useProtectedApi';

interface Props {
    isLoggedIn?: boolean;
}

export const Checkout: React.FC<Props> = ({ isLoggedIn }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { setCheckoutStep } = useCheckoutStep()
    const { setCurrentPermitForm } = useCurrentPermit();
    const { currentFee, setCurrentFee} = useCurrentFee();
    const { anonymousToken, setAnonymousToken } = useAnonymousToken();
    const { paymentPayload } = usePaymentPayload();
    const [loading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate();
    const stripeService = new Service('stripe');
    const { setCitizenDashboardStep } = useCitizenDashboardStep();
    const { isLoading, postData: savePermit } = useProtectedApi({
        route: 'myApplicants'
    })

    const payFee = async (event: any) => {
        event.preventDefault();
        // is no elements and no stripe don't do anything
        if (elements == null || stripe === null) {
            return;
        }
        setIsLoading(true)
        // payment intent
        const res = await axios.post(
            `${API_URL}/get-stripe-token`,
            { amount: currentFee },
            {
                headers: {
                    'Authorization': `Bearer ${anonymousToken}`,
                    "Accept": "application/json",
                },
            }) as any;

        const permit = await axios.post(
            `${API_URL}${allRoutes.myApplicants}`,
            paymentPayload,
            {
                headers: {
                    'Authorization': `Bearer ${anonymousToken}`,
                    "Accept": "application/json",
                },
            }) as any
        
        if (get(permit, 'error.response.data')) {
            toast.error('something went wrong, please try again with a new submission.')
            navigate('/', { replace: true }) // redirect the user
            setIsLoading(false);
            return
        }

        // error the just return a message
        if (get(res, 'error.response.data')) {
            if (get(res, 'error.response.status') === 401) {
                toast.error('This session has expired, please try again with a new submission.')
                navigate('/', { replace: true }) // redirect the user
            }
            setIsLoading(false)
            return toast.error('Something went wrong!, please try again!')
        }

        const clientSecret = res.data.stripe_secret_intent as string;
        // confirm payment
        const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
                payment_method: {
                    card: elements.getElement(CardElement) as any,
                    billing_details: {
                        email: paymentPayload.form.personalInfo.email,
                        address: {
                            ...omit(paymentPayload.form.address, ['zip', 'line_one', 'line_two','birthday', 'phone']) as any,
                            line1: paymentPayload.form.address.line_one,
                            line2: paymentPayload.form.address.line_two,
                            postal_code: paymentPayload.form.address.zip
                        }
                    }
                }
            });
        // is any error display it to the page
        if (error) {
            setIsLoading(false)
            return toast.error(error.message || '');
        }


        setIsLoading(false);

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            toast.success('');

            setAnonymousToken('') // clear the current token;
            setCheckoutStep(0) // reset the form;
            setCurrentPermitForm(new PermitForm());
            setCurrentFee(0)

            navigate(`${routes.permit_payment}?active=true`, { replace: true })

        }

    };
    const loggedInPayFee = async (event: any) => {
        event.preventDefault();
        // is no elements and no stripe don't do anything
        if (elements == null || stripe === null) {
            return;
        }
        setIsLoading(true)
        // payment intent
        const res = await stripeService.post({ amount: currentFee });
        const permit = await savePermit(paymentPayload);

        if (get(permit, 'error.response.data')) {
            toast.error('something went wrong, please try again with a new submission.')
            navigate('/', { replace: true }) // redirect the user
            setIsLoading(false);
            return
        }
        // error the just return a message
        if (get(res, 'response.data')) {
            setIsLoading(false)
            return toast.error('Something went wrong!, please try again!')
        }

        const clientSecret = get(res, 'stripe_secret_intent', '') || '';
        // confirm payment
        const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
                payment_method: {
                    card: elements.getElement(CardElement) as any,
                    billing_details: {
                        email: paymentPayload.form.personalInfo.email,
                        address: {
                            ...omit(paymentPayload.form.address, ['zip', 'line_one', 'line_two', 'birthday', 'phone']) as any,
                            line1: paymentPayload.form.address.line_one,
                            line2: paymentPayload.form.address.line_two,
                            postal_code: paymentPayload.form.address.zip
                        }
                    }
                }
            });
        // is any error display it to the page
        if (error) {
            setIsLoading(false)
            return toast.error(error.message || '');
        }


        setIsLoading(false);

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            toast.success('Application submitted.');
            setCheckoutStep(0);
            setCitizenDashboardStep(0);
            setCurrentPermitForm(new PermitForm())
            setCurrentFee(0);
        }

    };


    function paymentAction(logged: boolean | undefined) {
        if (logged) return loggedInPayFee

        return payFee
    }

    function back(step: number) {
        return () => setCheckoutStep(step);
    }

    if (!stripe) return <LoadingPage />

    return (
        <div className="mt-5 w-full">
            <form onSubmit={paymentAction(isLoggedIn)}>
                <CardElement className='p-4 border-2 w-full rounded-md border-primarySteel' options={{ disabled: loading }} />
                <div className="flex items-center justify-between mt-10">
                    {!isLoggedIn && <ReadyButton type='button' buttonMode='outline' buttonType='info' onClick={back(0)}>
                        Back
                    </ReadyButton>}
                    {!isLoggedIn && <div className='px-6 lg:px-24'></div>}
                    <ReadyButton disabled={!stripe || loading || isLoading} loading={loading}>
                        Pay
                    </ReadyButton>
                </div>
            </form>
        </div>
    )
}
