import { Layout } from 'layout/Layout';
import { ReadyButton } from 'components/button';
import { ReadyInput } from 'components/input';
import { useQuery } from 'hooks/useQuery';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPassword } from 'hooks/useResetPassword';
import { MdVerified } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const schema = Yup.object({
  password: Yup.string()
    .min(6, 'Minimum of 6 characters')
    .required('this field must not be empty!.'),
  password_confirmation: Yup.string()
    .min(6, 'Minimum of 6 characters')
    .required('this field must not be empty!.'),
}).required();

type Reset = {
  password: string;
  password_confirmation: string;
};

export const NewPassword = () => {
  const token = useQuery().get('token');
  const email = useQuery().get('email');
  const name = useQuery().get('full_name');

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Reset>({
    resolver: yupResolver(schema),
  });

  const { changePassword, error, isLoading, success } = useResetPassword();

  const submit = handleSubmit(async (data) => {
    if (token && email) {
      const payload = { ...data, token, email };
      await changePassword(payload);
    }
  });

  function goBack() {
    navigate('/', { replace: true });
  }

  return (
    <Layout>
      <div className='px-4 max-w-md mx-auto flex justify-center items-center flex-col h-full font-sans'>
        <div className='w-full text-center mb-3'>
          <h1 className='text-primarySteel  font-bold'>Password Reset</h1>
          <h4 className='text-primarySteel font-medium'>{name}</h4>
        </div>
        {success === 'idle' && (
          <>
            <p className='text-gray-400 text-sm text-center'>
              Use the form below to set your new password
            </p>

            <form className='px-4 my-10 w-full max-w-sm' onSubmit={submit}>
              <ReadyInput
                {...register('password')}
                error={errors.password?.message}
                type='password'
                required
                placeholder='New password'
              />
              <ReadyInput
                {...register('password_confirmation')}
                error={errors.password_confirmation?.message}
                type='password'
                required
                placeholder='Confirm password'
              />
              {error && (
                <h6 className='text-primaryFailure font-sans text-xs font-bold text-center w-full'>
                  {error.message}
                </h6>
              )}
              <div className='max-w-[240px] mx-auto mt-6'>
                <ReadyButton type='submit' loading={isLoading}>
                  save new password
                </ReadyButton>
              </div>
            </form>
          </>
        )}

        {success === 'ok' && (
          <div className='flex flex-col items-center justify-center p-3 rounded-md'>
            <MdVerified className='text-xl text-ready-orange mb-5' />
            <p className='text-grayScaleGrayDark text-justify mb-6 max-w-xs mx-9 font-medium'>
              Your password have been change successfully, please go back to
              home page and login again!.
            </p>
            <div className='max-w-md'>
              <ReadyButton
                buttonMode='outline'
                buttonType='info'
                onClick={goBack}
                loading={isLoading}>
                go back
              </ReadyButton>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
