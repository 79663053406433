import { useCurrentApplicant, useCurrentApplicants } from 'store';
import { useMemo } from 'react';
import { ReadyButton } from 'components/button';
import moment from 'moment';
import { useProtectedApi } from 'hooks/useProtectedApi';

export const AdminApplicantDetail = () => {
  const { currentApplicant } = useCurrentApplicant();
  const { currentApplicants } = useCurrentApplicants();
  const isApplicant = currentApplicant.id;
  const isApplicants = currentApplicants.length;

  const {postData: reject, isLoading: isRejectLoading, } = useProtectedApi({
    route: 'rejectApplicant'
  });
  const {postData: approve, isLoading: isApproveLoading, } = useProtectedApi({
    route: 'approveApplicant'
  });

  const canShowDetails = useMemo(() => {
    if (currentApplicant.id) {
      return !currentApplicant.id
    }

    if (currentApplicants.length) {
      return !currentApplicants.length
    }
    return true
  }, [currentApplicant.id, currentApplicants])

  const isApplicantsProps = {
    primary: {
      title: 'Accept all',
      action: async () => approve({ applications: currentApplicants }),
      isLoading: isApproveLoading
    },
    secondary: {
      title: 'Reject all',
      action: async () => reject({ applications: currentApplicants }),
      isLoading: isRejectLoading
    }
  }
  const isApplicantProps = {
    primary: {
      title: 'Accept',
      action: async () => approve({ application: currentApplicant.id }),
      isLoading: isApproveLoading
    },
    secondary: {
      title: 'Reject',
      action: async () => reject({ application: currentApplicant.id }),
      isLoading: isRejectLoading
    }
  }

  return (
    <div>
      <h1 className="text-primarySteel font-bold mb-2">Applicant details</h1>
      {(canShowDetails) && <p className="text-sm text-grayScaleGrayMedDark">select an applicant to learn more about it.</p>}
      {
        (!!isApplicant && !isApplicants) && (
          <div className='w-full'>
            <h2 className='text-grayScaleGrayMedDark font-bold'>{currentApplicant.form.personalInfo.first_name} {currentApplicant.form.personalInfo.last_name} - {currentApplicant.permit.name}</h2>
            <div className='w-full mt-6 flex'>
              <InfoBox
                title='Location'
                sub={`${currentApplicant.form.address.state}, ${currentApplicant.form.address.city}`}
              />
              <InfoBox
                title='Received on'
                sub={`${moment(currentApplicant.created_at).format('YYYY/MM/DD')}`}
              />
              <InfoBox
                title='Status'
                sub={`${currentApplicant.status.name}`}
              />
            </div>
            <div className='my-6'>
              <h2 className='text-primarySteel font-bold mb-2'>BackGround Check</h2>
              <div className='h-[20rem] border-primarySteel border-2 rounded-xl p-2'>
                {currentApplicant.background_check}
              </div>
              <Actions {...isApplicantProps}/>
            </div>
          </div>
        )
      }
      {
        !!isApplicants && (
          <div className='w-full'>
            <p className='text-grayScaleGrayMedDark my-4'>
              Multiple applications selected. Select a s single application to see more details about it, or choose
              an action to apply to all selected applications.
            </p>
            <Actions {...isApplicantsProps}/>
          </div>)
      }
    </div>
  )
}

function InfoBox({ title, sub }: { title: string, sub: string }) {
  return (<div className='w-fit text-grayScaleGrayMedDark mr-12 last:mr-0'>
    <h5 className='font-bold'>{title}</h5>
    <p>{sub}</p>
  </div>);
}
interface IActions {
  primary: {
    title: string,
    action?: () => void,
    isLoading?: boolean
  },
  secondary: {
    title: string,
    action?: () => void
    isLoading?: boolean
  }
}
function Actions({ primary, secondary}: IActions) {
  return (
    <div className='w-full flex items-center justify-between'>
      <ReadyButton wrapperClass='bg-primarySuccess' onClick={primary.action} loading={primary.isLoading}>
        { primary.title}
      </ReadyButton>
      <div className='px-4'></div>
      <ReadyButton wrapperClass='bg-primaryFailure' onClick={secondary.action} loading={secondary.isLoading}>
        { secondary.title}
      </ReadyButton>
    </div>
  )
}