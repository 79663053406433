import { AuthHeader } from "components/header/AuthHeader"
import { FC, ReactNode } from "react"

interface Props {
  leftPanel?: JSX.Element,
  rightPanel?: ReactNode,
  hideRightPanel?: boolean
}

export const AuthLayout: FC<Props> = ({ leftPanel, rightPanel, hideRightPanel }) => {

  return (
    <>
    <div className="h-fit  lg:h-screen  overflow-auto flex flex-col font-sans">
      <AuthHeader />
      <div className="flex-grow flex justify-center items-center flex-col lg:flex-row overflow-y-auto">
        <div className={`px-6 py-6 md:py-12 h-fit md:h-full w-full lg:w-8/12`}>
          {leftPanel}
        </div>
        {!hideRightPanel && <div className="hidden lg:block w-[.4rem] bg-grayScaleGrayMedDark/10 h-full"></div>}

        {!hideRightPanel && <div className="p-4 lg:p-12 h-full w-full lg:w-[50%]">
          {rightPanel}
        </div>}
      </div>
    </div>
    </>
  )
}
