import { ReadyButton } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes/index';


export const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="h-screen flex justify-center items-center flex-col text-grayScaleGrayDark">
            <h1 className='text-5xl'>404</h1>
            <p>Ups! seems like you found something broken!</p>

            <div className='w-[10rem] mt-5'>
                <ReadyButton onClick={() => navigate(routes.home, { replace: true})}>
                    Go Back
                </ReadyButton>
            </div>
        </div>
    )
}
