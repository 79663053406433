import { API_URL, authRoutes, RPNameSpaces } from "api/api.config";
import axios, { AxiosError } from "axios";
import toast from 'react-hot-toast';
export class UnprotectedServices implements IAuth {
    constructor(private apiRoute: keyof typeof authRoutes){}

    private setToken(token: string): void {
        localStorage.setItem(RPNameSpaces.token, token);
        localStorage.setItem(RPNameSpaces.isAuth, 'true');
    }

    public async signIn<P extends Object>(payload: P): Promise<SignInResponse | IError> {
        try {
            const response = await axios.post<SignInResponse>(`${API_URL}${authRoutes[this.apiRoute]}`, payload);
            
            this.setToken(response.data.access_token)

            return response.data
        } catch (error) {
            localStorage.removeItem(RPNameSpaces.isAuth);
            return error as AxiosError
        }
    }
    public async post<T>(payload: T): Promise<T | IError> {
        try {
            const response = await axios.post<T>(`${API_URL}${authRoutes[this.apiRoute]}`, payload);

            return response.data
        } catch (error) {
            toast.error("We want to say sorry, this operation didn't succeeded");
            return error as AxiosError
        }
    }
    public async get<T>(query: string = ''): Promise<T | IError> {
        try {
            const response = await axios.get<T>(`${API_URL}${authRoutes[this.apiRoute]}${query}`);

            return response.data
        } catch (error) {
            return error as AxiosError
        }
    }

    public async resetPassword<T>(payload: T): Promise<any | IError> {
        try {
            const response = await axios.post<SignInResponse>(`${API_URL}${authRoutes[this.apiRoute]}`, payload);

            return response.data
        } catch (error) {

            return error as AxiosError
        }
    }
}