import { PermitForm } from 'components/PermitForm';
import { Checkout } from 'components/PermitForm/checkout';
import { Layout } from 'layout/Layout';
import { useCurrentPermit, useCurrentFee } from 'store';
import { useCheckoutStep } from 'store';
import { formatter } from 'utils/currency/formatter';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingPage } from 'pages/LoadPage';
import { usePreventReload } from '../../hooks/usePreventReload';


function getMessage(type: number): JSX.Element {
   if(type === 0) return <>This permit requires you to submit to a background check. Fill the form below and click <b>submit</b>. Depending on the background check results, you should know the result of your application 2-5 business days.</>

   return <>This Permit has an application fee. Once you submit your payment, we'll start your background check immediately</>
}

export const PermitPage = () => {
  const { checkoutStep } = useCheckoutStep();
  const { currentPermitForm } = useCurrentPermit();
  const [currentCity] = currentPermitForm.cities;
  // const [fee] = currentPermitForm.fees || [0];
  const { currentFee} = useCurrentFee();

  const navigate = useNavigate();
  usePreventReload()

  useEffect(() => {
    if(!currentPermitForm.id) {
      navigate('/', { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPermitForm.id])

  if (currentPermitForm.id === 0) return <LoadingPage />
  
  return (
    <Layout containerClasses='py-0'>
      <div className='flex items-center justify-center flex-col w-full font-sans'>
        <div className='md:border-4 border-primarySteel rounded-lg p-6 h-full w-full md:w-[40rem]'>
          <h1 className='font-bold text-primarySteel'>New Applicant</h1>
          <h4 className='text-grayScaleGrayMedDark font-bold'>{currentCity?.name},{currentPermitForm.state.name} - {currentPermitForm.name}</h4>
          <p className='text-[11px] mt-3 text-grayScaleGrayMedDark'>{getMessage(checkoutStep)}</p>
          { checkoutStep === 1 && (
            <div className='w-fit mt-5'>
              <h5 className='text-grayScaleGrayDark/50 font-bold'>Fee</h5>
              <h1 className='text-primarySteel'>{formatter(currentFee)}</h1>
            </div>
          )}
          {
            checkoutStep === 0 && <PermitForm />
          }
          {
            checkoutStep === 1 && <Checkout />
          }
        </div>
      </div>
      <div className="h-20"></div>
    </Layout>
  )
}
