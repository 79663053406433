/* eslint-disable react-hooks/exhaustive-deps */
import { ActionTable } from 'components/ActionTable';
import { useProtectedApi } from 'hooks/useProtectedApi';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { CityApplicant } from 'store/Entities';



export const MyPermits: FC = () => {
    const { fetchData, data, isLoading } = useProtectedApi<CityApplicant[]>({
        route: 'myPermits'
    });

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <ActionTable<CityApplicant>
            hideHeader
            isLoading={isLoading}
            data={data?.filter(d => d.status.name === "Accepted") || []} cols={[
                {
                    name: 'PermitType',
                    class: 'w-[30%]',
                    field: 'permit.name',
                    render: ({ data }) => {
                        return <>{data.permit.name}</>
                    }
                },
                {
                    name: 'Location',
                    field: 'location',
                    render: ({ data }) => {
                        return <>{data.form.address.state}, {data.form.address.city}</>
                    }
                },
                {
                    name: 'Status',
                    field: 'status.name',
                    render: ({ data }) => {
                        return <>{data.status.name}</>
                    }
                },
                {
                    name: 'Application Date',
                    field: 'status.name',
                    render: ({ data }) => {
                        return <>{moment(data.created_at).format('YYYY/MM/DD')}</>
                    }
                }
            ]} />
    )
}
