import { Route, Routes } from 'react-router-dom';
import { MainScreen } from 'pages/Welcome';
import { ResetPassword } from 'pages/ResetPassword';
import { NewPassword } from 'pages/ResetPassword/[token]';
import { useEffect, useState } from 'react';
import { RPNameSpaces } from 'api/api.config';
import { SignUp } from 'pages/create-account';
import { routes } from 'utils/routes';
// pages
import { NotFound } from 'pages/404';
import { useUserApi } from 'hooks/useUser';
import { getRoutes } from 'appRoutes/getRoutes';
import { useUser } from 'store';
import { LoadingPage } from 'pages/LoadPage';
import { PermitPage } from 'pages/PermitPage';
import { Toaster } from 'react-hot-toast'
import { AfterPay } from './pages/create-account/after-pay/index';


function App() {
  const { getUser, isLoading } = useUserApi();
  const { user } = useUser();
  const [isAuthenticated, setShowDashboard] = useState(false)

  useEffect(() => {
    const auth = !!localStorage.getItem(RPNameSpaces.isAuth);
    if (auth) {
      getUser()
    }

    setShowDashboard(auth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading.status === 'get') {
    return <LoadingPage  showMessage/>
  }
  return <>
    <Toaster 
      position='bottom-right'
    />
    {
      isAuthenticated && user ? (<Routes>
        {getRoutes(user.roles_id || 0).map(routes => {
          return <Route key={routes.path} path={routes.path} element={routes.component} />
        })}
      </Routes>) :
        <Routes>
          <Route path={routes.home} element={<MainScreen />} />
          <Route path={routes.createAccount} element={<SignUp />} />
          <Route path={routes.resetPassword} element={<ResetPassword />} />
          <Route path={routes.setNewPassword} element={<NewPassword />} />
          <Route path={routes.permit} element={<PermitPage />} />
          <Route path={routes.permit_payment} element={<AfterPay />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
    }
  </>
}

export default App;
