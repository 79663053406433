import { AuthLayout } from 'layout/AuthLayout';
import { ReadySearchPermit } from 'sections/Citizen/Dashboard/SearchPermit';
import { CitizenDashboardTabs } from './Tabs/'
import { useCitizenDashboardStep } from 'store';
import { CitizenPermitPage } from '../Permits/index';

export const CitizenDashboard = () => {
  const { citizenDashboardStep } = useCitizenDashboardStep();
  const Views = [<ReadySearchPermit />, <CitizenPermitPage />]

  return (
    <AuthLayout
      leftPanel={Views[citizenDashboardStep]}
      hideRightPanel={citizenDashboardStep === 1}
      rightPanel={<CitizenDashboardTabs />}
    />
  )
}
