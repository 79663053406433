/* eslint-disable react-hooks/exhaustive-deps */
import { AuthLayout } from 'layout/AuthLayout';
import { useUser } from 'store';
import { getUserAccount } from 'utils/getUserAccount';
import ChangePassword from 'components/ChangePassword';
import { ActionTable } from 'components/ActionTable';
import { useProtectedApi } from 'hooks/useProtectedApi';
import { useEffect } from 'react';
import { Fees } from 'store/Entities';
import { formatter } from 'utils/currency/formatter';
import moment from 'moment';

export const CitySettings = () => {
  const { user } = useUser();
  const { fetchData, data, isLoading } = useProtectedApi<Fees[]>({
    route: 'fees'
  });

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <AuthLayout
      hideRightPanel
      leftPanel={
        <div className='p-4 w-full h-full'>
          <div className="container mx-auto w-full">
            <div>
              <h1 className='text-primarySteel text-3xl'>Settings</h1>
            </div>
            <div className='flex mt-6 flex-col md:flex-row gap-4 md:gap-12'>
              {/* col1 */}
              <div className='w-fit'>
                <h1 className='text-primarySteel font-bold'>Account info</h1>
                <div className="w-fit h-full py-6 select-none">
                  <div className="w-full">
                    <h4 className='text-grayScaleGrayDark/60 font-bold'>Account type</h4>
                    <div className="mt-4 p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 text-grayScaleGrayMedDark opacity-50">
                      {getUserAccount(user?.roles_id || 0)}
                    </div>
                  </div>
                  <div className='flex mt-4'>
                    <div className="w-fit">
                      <h4 className='text-grayScaleGrayDark/60 font-bold'>City</h4>
                      <div className="p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 w-[10rem] text-grayScaleGrayMedDark opacity-50">
                        {user?.cities.name}
                      </div>
                    </div>
                    <div className="px-3"></div>
                    <div className="w-full">
                      <h4 className='text-grayScaleGrayDark/60 font-bold'>State</h4>
                      <div className="p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 text-grayScaleGrayMedDark opacity-50">
                        {user?.states.name}
                      </div>
                    </div>
                  </div>
                  <p className='text-xs mt-2 text-grayScaleGrayDark/50 font-bold'>is something wrong here? Contact us at 555-5555-555 <br /> or company@email.com and we'll get it resolved.</p>

                  <div className="w-full mt-6">
                    <h4 className='text-grayScaleGrayDark/60 font-bold'>Email address</h4>
                    <div className="mt-4 p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 text-grayScaleGrayMedDark opacity-50">
                      {user?.email}
                    </div>
                  </div>
                  <ChangePassword />
                </div>
              </div>
              {/* col 2 */}
              <div className='flex-grow'>
                <h1 className='text-primarySteel font-bold mb-6'>Permit Types / Permit Fees</h1>
                <ActionTable<Fees>
                  data={data || []}
                  hideHeader
                  isLoading={isLoading}
                  cols={[
                    {
                      name: 'Permit Name',
                      field: 'name',
                    },
                    {
                      name: 'Fee',
                      field: 'amount',
                      render: ({ data: { amount }}) => {
                        return <>{formatter(amount, 'USD')}</>
                      }
                    },
                    {
                      name: 'Expiry',
                      field: 'expire_at',
                      render: ({ data: { expire_at }}) => {
                        return <>{moment(expire_at).format('YYYY-MM-DD')}</>
                      }
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      } />
  )
}
