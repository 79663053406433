import { useState } from 'react';
import { UnprotectedServices } from '../api/unprotected/index';
import { authRoutes } from 'api/api.config';
import { get, isEmpty } from 'lodash';


type RoutesKeys = keyof typeof authRoutes;

type Props<T> = {
    route: RoutesKeys,
    storeFunction?: (data: T) => void
}

export function useApi<T>({ route, storeFunction }: Props<T>) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<T | undefined>(undefined);
    const api = new UnprotectedServices(route);


    async function fetchData(query: string = '') {
        setIsLoading(true);

        const res = await api.get(query);

        if (!isEmpty(res)) {
            setData(res as T);

            if (storeFunction) storeFunction(res as T)

            setIsLoading(false);
            return
        }

        setIsLoading(false);
        setData(undefined);

    }

    async function postData<T extends Object>(payload: T) {
        setIsLoading(true);

        const res = await api.post(payload);

        if (get(res, 'error.response.data')) {
            setIsLoading(false);
            setData(undefined);
            return Promise.reject(res);
        }

        setData(res as any);

        if (storeFunction) storeFunction(res as any)

        setIsLoading(false);
        return Promise.resolve(data)

    }


    return {
        isLoading, fetchData, data, postData
    }
}