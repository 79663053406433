import axios from "axios";

export enum RPNameSpaces {
    token = 'RPToken',
    isAuth = 'isAuth',
    anonymousToken = 'RPAnonymousToken'
}

const API_URL = process.env.REACT_APP_API_URL || ''

const token = localStorage ? localStorage.getItem(RPNameSpaces.token) : '';


const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Authorization': `Bearer ${token}`,
        "Accept": "application/json",
    }
});


const authRoutes = {
    signIn: "/sign-in",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    signUp: "/sign-up",
    cities: "/cities",
    state: "/states",
    permit: "/permits",
    anonymousUser: "/sign-up-anonymous",
    stripe: "/get-stripe-token",
    applicants: '/admin/applications',
    myApplicants: '/applications',
    approveApplicant: '/admin/applications/approve',
    rejectApplicant: '/admin/applications/reject',
    changePassword: '/auth/change-password',
    invite: '/admin/users/invite',
    myPermits: '/users/0/applications',
    fees: '/admin/users/cities/fees',
}
const apiRoutes = {
    adminCities: "/admin/cities",
    user: "/profile"
}

const allRoutes = { ...apiRoutes, ...authRoutes }


export { api, API_URL, authRoutes, apiRoutes, allRoutes }

