/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, useForm } from 'react-hook-form';
import { useCurrentPermit, useCheckoutStep, usePaymentPayload, useAnonymousToken, useStates } from 'store';
import { ReadyInput } from 'components/input';
import { ReadyButton } from 'components/button';
import { useState, useEffect, forwardRef } from 'react';
import { MdDelete } from 'react-icons/md';
import { omit, isEmpty, get } from 'lodash';
import { Buffer } from 'buffer'
import { PermitForm as TPermitForm, permitResolver } from './schema'
import { useApi } from 'hooks/useApi';
import { useUser } from 'store';
import moment from 'moment';
import Select from 'react-select';
import { styles } from 'utils/styles';

interface Props {
    isLoggedIn?: boolean
}

export const PermitForm: React.FC<Props> = ({ isLoggedIn }) => {
    const { setPaymentPayload } = usePaymentPayload();
    const { register, handleSubmit, formState: { isDirty, errors, }, getValues, watch, resetField, setValue, control } = useForm<TPermitForm>({
        resolver: permitResolver,
    });
    const { user } = useUser();
    const { currentPermitForm } = useCurrentPermit();
    const { setCheckoutStep } = useCheckoutStep();
    const { setAnonymousToken } = useAnonymousToken();
    const { isLoading, postData } = useApi<{ access_token: string }>({
        route: 'anonymousUser',
        storeFunction: res => setAnonymousToken(res.access_token)
    });
    const { states } = useStates();

    const [img, setImage] = useState<string>('');
    const photo = watch('license');
    const permitBase = { 
        permits_id: currentPermitForm.id,
        application_date: moment().format('YYYY-MM-DD'),
        states_id: currentPermitForm.state.id,
        cities_id: currentPermitForm.cities[0].id,
    }

    async function createFile(data: TPermitForm) {
        try {
            const buffer = await data.license[0].arrayBuffer();
            const file = Buffer.from(buffer).toString('base64');

            const payload = {
                ...permitBase,
                user_id: data.email,
                form: {
                    personalInfo: {
                        ...omit(data, ['confirmation', 'line_one', 'line_two', 'state', 'city', 'zip',]),
                        license: file,
                    },
                    address: {
                        ...omit(data, ['confirmation', 'ssn', 'last_name', 'first_name', 'license', 'email'])
                    }
                }
            }

            if (!isLoggedIn) {
                // api operation here,
                const [{ status }] = await Promise.allSettled([postData({
                    email: data.email
                })]);

                if (status === 'fulfilled' && !isLoggedIn) {
                    // save payload to store;
                    setPaymentPayload(payload);

                    // navigate to next step
                    setCheckoutStep(1);
                }
                return
            }
            setPaymentPayload(payload);

            setCheckoutStep(1);
        } catch (error) {
            console.warn('we could operate this', error)
        }
    }

    const sendUserInfo = handleSubmit(async data => {
        let payload = {
            ...permitBase,
            user_id: data.email,
            form: {
                personalInfo: {
                    ...omit(data, ['confirmation', 'line_one', 'line_two', 'state', 'city', 'zip', 'license']),
                    license: ''
                },
                address: {
                    ...omit(data, ['confirmation', 'ssn', 'last_name', 'first_name', 'license', 'email'])
                }
            }
        }

        if (isEmpty(data.license) && !isLoggedIn) {
            // api operation here,
            const [{ status }] = await Promise.allSettled([postData({
                email: data.email
            })]);

            if (status === 'fulfilled') {
                // save payload to store;
                setPaymentPayload(payload);

                // navigate to next step
                setCheckoutStep(1);
            }

            return
        } else if (isLoggedIn) {
            // save payload to store;
            setPaymentPayload(payload);

            // navigate to next step
            setCheckoutStep(1);
        }

        createFile(data)
    })

    function clearPhoto() {
        resetField('license');
        setImage('')
    }

    useEffect(() => {
        if (!isEmpty(getValues('license'))) {
            try {
                const file = getValues('license') as any;
                const url = URL.createObjectURL(file[0]);

                setImage(url);
            } catch (error) {
                console.error("we could't upload the image", error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photo]);

    useEffect(() => {
        if (isLoggedIn && user) {
            setValue('email', user.email)
            setValue('first_name', user.first_name)
            setValue('last_name', user.last_name)
            setValue('birthday', user.birthday || '')
            setValue('phone', user.phone || '')
        }
    }, [isLoggedIn, user])

    const data = states.map(v => ({ value: v.id, label: v.name }));


    return (
        <form onSubmit={sendUserInfo} className='mt-4 flex flex-col' >
            <div className='flex w-full flex-col md:flex-row'>
                {/* first col */}
                <div className='w-full md:w-1/2 h-full'>
                    <h5 className='mb-4 text-grayScaleGrayMedDark font-bold'>Personal info</h5>
                    <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>First name</h4>
                        <ReadyInput  {...register('first_name')} type='text' error={errors.first_name?.message} placeholder='John' />
                    </label>
                    <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Last name</h4>
                        <ReadyInput  {...register('last_name')} type='text' error={errors.last_name?.message} placeholder='Doe' />
                    </label>
                    {!isLoggedIn && <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Email</h4>
                        <ReadyInput  {...register('email')} type='email' error={errors.email?.message} placeholder='example@mail.com' />
                    </label>}
                    <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Phone</h4>
                        <ReadyInput  {...register('phone')} type='phone' error={errors.phone?.message} placeholder='555-555-5555' />
                    </label>
                    {<label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Birthday</h4>
                        <ReadyInput  {...register('birthday')} type='date' error={errors.birthday?.message} placeholder='1990-12-12' />
                    </label>}
                    <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Social Security number</h4>
                        <ReadyInput  {...register('ssn')} type='text' error={errors.ssn?.message} placeholder='AAA-GG-SSSS' />
                    </label>
                </div>
                {/* second col */}
                <div className='px-6' />
                <div className='w-full md:w-1/2'>
                    <h5 className='mb-4 text-grayScaleGrayMedDark font-bold'>Current Address</h5>
                    <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Line 1</h4>
                        <ReadyInput  {...register('line_one')} type='text' error={errors.line_one?.message} />
                    </label>
                    <label className='text-md font-light text-grayScaleGrayMedDark'>
                        <h4>Line 2</h4>
                        <ReadyInput  {...register('line_two')} type='text' />
                    </label>

                    <div className='flex flex-col md:flex-row justify-between items-center md:w-auto w-full gap-4'>
                        <label className='text-md font-light text-grayScaleGrayMedDark w-full'>
                            <h4>City</h4>
                            <ReadyInput  {...register('city')} type='text' placeholder='Miami' error={errors.city?.message} />
                        </label>
                        <label className='text-md font-light text-grayScaleGrayMedDark w-full' >
                            <h4>State</h4>
                            <Controller
                                name='state'
                                control={control}
                                defaultValue={0 as any}
                                render={props => {
                                    return <Select
                                        styles={styles('100%')}
                                        placeholder='Fl'
                                        ref={props.field.ref}
                                        isSearchable
                                        isDisabled={!isDirty}
                                        classNamePrefix='disabled:border-dashed disabled:text-b-gray'
                                        value={data.filter(v => v.value === props.field.value)}
                                        onChange={e => props.field.onChange(get(e, 'value'))}
                                        options={data}
                                    />
                                }}
                            />
                            <div className='h-3 text-primaryFailure font-sans text-[11px] font-semibold text-left w-full my-1 p-[.2rem]'>
                                {errors.state?.message}
                            </div>
                        </label>
                        <label className='text-md font-light text-grayScaleGrayMedDark w-full'>
                            <h4>Zip code</h4>
                            <ReadyInput  {...register('zip')} type='text' error={errors.zip?.message} placeholder='33101' />
                        </label>
                    </div>
                    {/* end second cold */}
                </div>
            </div>
            <div className='mt-4 w-full'>
                <h5 className='text-primarySteel font-bold'>Photo id</h5>
                <p className='text-xs text-grayScaleGrayMedDark'>(A current driver's license, city or state ID - make sure your photo is clearly lit and not blurry)</p>
                <div className='w-full flex items-center justify-center md:justify-start'>
                    <ReadyImageInput {...register('license')} img={img} onClear={clearPhoto} />
                </div>
            </div>
            <label className='text-md font-light text-grayScaleGrayMedDark mt-4 w-full'>
                <div className='flex items-center justify-start'>
                    <input type="checkbox" {...register('confirmation')} />
                    <h5 className='ml-3 font-medium'>I affirm this information is correct and consent to a background check</h5>
                </div>
                <div className='h-3 text-primaryFailure font-sans text-[11px] font-semibold text-left w-full my-1 p-[.2rem]'>
                    {errors.confirmation?.message}
                </div>
            </label>
            <div className='flex items-center justify-end'>
                <div className='w-full md:w-20'>
                    <ReadyButton disabled={!isDirty || isLoading}>Next</ReadyButton>
                </div>
            </div>
        </form >
    )
}


interface FileProps {
    img: string,
    onClear?: () => void
}

const ReadyImageInput = forwardRef<HTMLInputElement, FileProps>((props, ref) => {
    const { img, onClear } = props;
    return <div className='rounded-xl border-2 w-[15rem] h-[10rem] border-primarySteel my-6 overflow-hidden justify-center items-center flex group relative'>
        {onClear && img && <div className='z-20 group-hover:flex hidden items-center justify-center inset-0 absolute bg-black/20 duration-300' onClick={event => event.stopPropagation()}>
            <button onClick={onClear} className='p-4 rounded-full bg-white/25 duration-300 cursor-pointer'>
                <MdDelete className='text-xl text-white' />
            </button>
        </div>}
        <label>
            {!img && <h1 className='w-fit p-2 text-sm rounded-md border font-bold text-primarySteel border-primarySteel px-4'>UPLOAD</h1>}
            {img && <img src={img} alt='no' className='rounded-md transform group-hover:scale-105 duration-300' />}
            <input {...omit(props, ['onCLear', 'img'])} ref={ref} type="file" multiple hidden />
        </label>
    </div>
})