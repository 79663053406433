import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  HTMLAttributes,
} from 'react';

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
  wrapperClass?: HTMLAttributes<HTMLInputElement>['className'];
  wrapperLabelClass?: HTMLAttributes<HTMLInputElement>['className'];
  color?: string
  success?: boolean
}

export const ReadyInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { error, success } = props;

  return (
    <label className='mb-3'>
      <input
        {...props}
        ref={ref}
        className={`w-full shadow-sm px-2 py-2 border rounded-md text-grayScaleGrayDark font-extralight ${
          error
            ? 'border-primaryFailure outline-none'
            : 'border-gray-200 outline-none'
        } ${
          success
            ? 'border-primarySuccess text-primarySuccess focus-within:border-primarySuccess'
            : ''
        } ${
          props.wrapperClass
        } focus-within:border-blue-500 focus-within:border-opacity-50 
        placeholder:font-normal placeholder:font-sans placeholder:text-sm
        `}
      />
      <div className='h-3 text-primaryFailure font-sans text-[11px] font-semibold text-left w-full my-1 p-[.2rem]'>
        {error}
      </div>
    </label>
  );
});
