type Props = {
    rtl?: 'justify-start' | 'justify-end',
    size?: {
        height?: string,
        width?: string
    },
    header?: {
        title: string,
        icon: JSX.Element,
        action: React.ReactNode
    },
    footer?: React.ReactNode,
    isOpen: boolean,
    onClose: () => void,
    renderHeader?: React.ReactNode
}

export const Sidebar: React.FC<Props> = (props) => {
    const { rtl, size, children } = props;
    const classes = `flex ${rtl} fixed inset-0 bg-black/50 z-10`;
    const innerClasses = `${size?.width || 'w-[60%] md:w-[25rem]'} ${size?.height || 'h-full'} bg-white flex flex-col`;

    return (props.isOpen ?
        <div className={classes} onClick={props.onClose}>
            <div className={innerClasses} onClick={e => e.stopPropagation()}>
                <div className="w-full h-fit p-4 flex justify-between items-center">
                    {props.renderHeader ? props.renderHeader :
                        (<>
                            <div className="flex items-end w-fit select-none">
                                <span className="text-2xl text-primarySteel mb-1 mr-2">{props.header?.icon}</span>
                                <h3 className="text-primarySteel font-bold">{props.header?.title}</h3>
                            </div>

                            {props.header?.action}
                        </>)
                    }
                </div>
                <div className="flex-grow">
                    {children}
                </div>
                <div className="h-[10%]">
                    {props.footer}
                </div>
            </div>
        </div> : <></>
    )
}
