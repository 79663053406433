import { Service } from "api/protected";
import { get } from "lodash";
import { useState } from 'react';
import { useUser } from "store";

export function useUserApi() {
    const api = new Service('user');
    const [isLoading, setIsLoading] = useState<{ status: 'get' | 'post' | 'put' | 'delete' | 'off' }>({
        status: 'off'
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, _] = useState<IError | undefined>(undefined);
    const [success, setSuccess] = useState<'ok' | 'idle'>('idle');
    const { setUser } = useUser()

    async function getUser() {
        setIsLoading({
            status: 'get'
        });
        const res = await api.get<User>();


        if (res && (get(res, 'id') || 0)) {
            
            setIsLoading({
                status: 'off'
            })
    
            setSuccess('ok')
            setUser(res as User);
            return
        }

        setIsLoading({
            status: 'off'
        })

    }

    return {
        isLoading,
        error,
        success,
        getUser
    }
}