import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

class PermitForm {
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  ssn: string = '';

  line_one: string = '';
  line_two: string = '';

  city: string = '';
  state: number = 0;
  zip: string = '';

  license: File[] = [];
  confirmation: boolean = false;

  phone: string = '';
  birthday: string = '';
}

const msg = {
  required: 'This field is required!',
  email: 'Provide a valid email!'
}

const permitSchema = yup.object().shape({
  first_name: yup.string().required(msg.required),
  last_name: yup.string().required(msg.required),
  email: yup.string().email(msg.email).required(msg.required),
  line_one: yup.string().required(msg.required),
  line_two: yup.string().notRequired(),
  city: yup.string().required(msg.required),
  state: yup.number().required(msg.required),
  zip: yup.string().required(msg.required),
  confirmation: yup.boolean().oneOf([true] ,msg.required),
  ssn: yup.string().required(msg.required),
  phone: yup.string().required(msg.required),
  birthday: yup.string().required(msg.required),
});


const permitResolver = yupResolver(permitSchema);


export {
  permitResolver,
  PermitForm
}