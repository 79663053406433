import { AdminDashboard } from 'pages/Admin/Dashboard/index';
import { AppRoute } from 'appRoutes/getRoutes';
import { routes } from '../utils/routes/index';
import { AdminSettings } from 'pages/Admin/Settings/Settings';
import { Invite } from '../pages/Admin/invite/index';


export const adminRoutes: Array<AppRoute> = [
    {
        path: routes.home,
        component: <AdminDashboard />
    },
    {
        path: routes.settings,
        component: <AdminSettings />
    },
    {
        path: routes.invite,
        component: <Invite />
    }
];