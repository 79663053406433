import { Loader } from 'components/Loader';
import { omit } from 'lodash';
import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ButtonHTMLAttributes,
} from 'react';
import { buttonType } from 'utils/btnTypes';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  wrapperClass?: HTMLAttributes<HTMLButtonElement>['className'];
  buttonType?: RPButtonType;
  buttonMode?: RPButtonMode;
  loading?: boolean
}

export const ReadyButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    return (
      <button
        {...omit(props, [
          'wrapperClass',
          'buttonType',
          'buttonMode',
          'loading',
        ])}
        ref={ref}
        data-testid='button-test'
        disabled={props.disabled || props.loading}
        className={`${
          props.loading ? 'cursor-not-allowed' : 'cursor-pointer'
        } ${props.loading ? 'border-grayScaleGrayMed' : ''} flex justify-center items-center active:bg-opacity-80 duration-300 disabled:cursor-not-allowed disabled:bg-grayScaleGrayMed disabled:text-white p-2 my-2 text-xs  font-bold uppercase rounded-md w-full ${buttonType(
          props.buttonType || 'idle',
          props.buttonMode || 'fill'
        )} ${props.wrapperClass}`}>
          {props.loading && <span className='mr-3'><Loader size={ 12 }/></span>}
        {props.children}
      </button>
    );
  }
);
