import { ReadyHeader } from 'components/header';
import { FC } from 'react';

interface Props {
  classWrapper?: string,
  containerClasses?: string
}

export const Layout: FC<Props> = ({ children, classWrapper, containerClasses }) => {
  return (
    <div className={`App h-screen bg-gradient-to-b from-grayScaleGray via-grayScaleGray to-grayScaleGrayMed overflow-y-auto ${classWrapper || ''}`}>
      <ReadyHeader />
      <div className={`h-4/5 md:p-4 box-border container mx-auto  ${containerClasses || 'py-5'}`}>
        {children}
      </div>
    </div>
  );
};
