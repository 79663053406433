import { FC, useState } from 'react';

type ITab = {
    name: string,
    component: JSX.Element,
}

type Props = {
    tabs: ITab[],
    defaultTab?: number,
    onChangeTab?: (tab: number) => void,
    actions?: Array<Action>,
}

type Action = {
    onClick: () => void,
    icon: React.ReactNode,
    isVisible?: boolean,
    id: string
}

const TabBody: FC<ITab> = function ({ component }) {
    return component
}

const TabHeader: FC<ITab & { change(): void, current: boolean }> = function ({ name, change, current }) {
    return <button className={`  font-bold px-4 `} onClick={change}>
        <h1 className={`text-sm md:text-2xl duration-150 pb-2 border-b-4 text-primarySteel hover:border-primarySteel ${current ? 'border-primarySteel' : 'border-transparent'}`}>
            {name}
        </h1>
    </button>
}

export const Tabs: FC<Props> = ({ tabs, defaultTab, onChangeTab, actions }) => {
    const [currentTab, setCurrentTab] = useState(defaultTab);

    function changeTab(n: number) {
        return () => {
            setCurrentTab(n);
            onChangeTab && onChangeTab(n);
        }
    }

    return (
        <div className='font-sans flex flex-col'>
            <div className='mb-6 flex items-center justify-between'>
                <div className='flex'>
                    {
                        tabs.map((tab, i) => {
                            return <TabHeader {...{ ...tab, current: currentTab === i }} key={i} change={changeTab(i)} />
                        })
                    }
                </div>
                <div className='flex items-center'>
                    {actions?.map(action => action.isVisible ? <button className='cursor-pointer p-2 rounded-full bg-grayScaleGrayMedDark/20 text-primaryBlue' key={action.id} onClick={action.onClick}>
                        {action.icon}
                    </button> : null)}
                </div>
            </div>
            <div className='px-4 py-2'>
                {
                    tabs.map((tab, idx) => {
                        if (idx === currentTab) {
                            return <TabBody key={idx} {...tab} />
                        }
                        return <></>
                    })
                }
            </div>
        </div>
    )
}
