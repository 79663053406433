import { City } from 'sections/create-account/city';
import { Layout } from 'layout/Layout';
import { SignUpForm } from 'sections/create-account/SignUpForm';



export const SignUp = () => {
    return (
        <Layout>
            <div className='h-full md:flex justify-center flex-nowrap '>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className='w-full md:w-1/2 lg:w-4/5  md:mx-6'>
                        <SignUpForm />
                    </div>
                    <div className='w-full md:w-1/2 lg:w-4/5 h-full md:mx-6'>
                        <City />
                    </div>
                </div>
            </div>
            <div className='h-[10rem]'></div>
        </Layout>
    )
}
