import { colorHelper } from "utils/colorBuilder";

export function buttonType(type: RPButtonType, mode: RPButtonMode) {
    const typeMode = () => {
        if(mode === 'outline') return 'border'
        return 'bg'
     }
    return {
        idle: `${colorHelper('primaryBlue', typeMode())} ${mode === 'outline' ? 'active:bg-primaryBlue active:text-white  border-2': ''} ${colorHelper(mode === 'outline' ? 'primaryBlue' : 'white', 'text')}`,
        success: `${colorHelper('primarySuccess', typeMode())} ${mode === 'outline' ? 'active:bg-primarySuccess active:text-white  border-2': ''} ${colorHelper(mode === 'outline' ? 'primarySuccess' : 'white', 'text')}`,
        error: `${colorHelper('primaryFailure', typeMode())} ${mode === 'outline' ? 'active:bg-primaryFailure active:text-white  border-2': ''} ${colorHelper(mode === 'outline' ? 'primaryFailure' : 'white', 'text')}`,
        disabled: ' ',
        info: `${colorHelper('primarySteel', typeMode())} ${mode === 'outline' ? 'active:bg-primarySteel active:text-white  border-2': ''} ${colorHelper(mode === 'outline' ? 'primarySteel' : 'white', 'text')}`,
    }[type];
}