import { IconBox } from "components/IconBox";
import { MdLocationCity } from "react-icons/md";

export const City = () => {

    return (
        <IconBox color='ready-orange' title='city' icon={<MdLocationCity />}>
            <div className='text-center md:px-9 py-10 lg:px-6 px-4 lg:h-[38rem]'>
                <h1 className='hidden md:block capitalize font-bold text-ready-orange mb-3'>
                    City
                </h1>
                <p className="text-grayScaleGrayMedDark text-sm">ReadyPermit makes keeping track of permit applicants and auto-approval much simpler. Get in touch with us and we'll set you an account</p>

                <div className="mt-16">
                    <h3 className="text-primarySteel">Email</h3>
                    <a className="text-xl text-grayScaleGrayDark" href="mailto:office@readypermit.com">office@readyPermit.com</a>

                    <h3 className="mt-10 text-primarySteel">Phone</h3>
                    <a href="tel:555-555-5555" className="text-xl text-grayScaleGrayDark">555-555-5555</a>
                </div>
            </div>
        </IconBox>
    );
};
