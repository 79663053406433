import { AppRoute } from "appRoutes/getRoutes";
import { CitizenDashboard } from 'pages/Citizen/Dashboard/index';
import { CitizenSettings } from "pages/Citizen/Settings";
import { routes } from 'utils/routes/index';

export const citizenRoutes: AppRoute[] = [
    {
        path: routes.home,
        component: <CitizenDashboard />
    },
    {
        path: routes.settings,
        component: <CitizenSettings />
    }
]