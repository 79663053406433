import { AuthLayout } from 'layout/AuthLayout';
import { useUser } from 'store';
import { getUserAccount } from 'utils/getUserAccount';
import ChangePassword from 'components/ChangePassword';

export const AdminSettings = () => {
    const { user } = useUser();

    return (
        <AuthLayout
            hideRightPanel
            leftPanel={
                <div className='p-4 w-full h-full'>
                    <div className="container mx-auto w-full lg:w-1/2">
                        <div>
                            <h1 className='text-primarySteel text-3xl'>Settings</h1>
                        </div>
                        <div>
                        <div className="w-1/2 h-full py-6 select-none">
                                <div className="w-fit">
                                    <h4 className='text-grayScaleGrayDark/60 font-bold'>Account type</h4>
                                    <div className="mt-4 p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 w-[15rem] text-grayScaleGrayMedDark opacity-50">
                                        {getUserAccount(user?.roles_id || 0)}
                                    </div>
                                </div>
                                <div className="w-fit mt-12">
                                    <h4 className='text-grayScaleGrayDark/60 font-bold'>Email address</h4>
                                    <div className="mt-4 p-2 px-4 rounded-md border-2 border-grayScaleGrayDark/50 w-[15rem] text-grayScaleGrayMedDark opacity-50">
                                        {user?.email}
                                    </div>
                                </div>
                                <ChangePassword />
                            </div>
                        </div>
                    </div>
                </div>
            } />
    )
}
