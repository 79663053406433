import { Sidebar } from 'components/Sidebar'
import { MdLocationCity, MdClose } from 'react-icons/md'
import { useSideBarDialog, useCities } from 'store';
import { useForm } from 'react-hook-form';
import { ReadyButton } from '../../../../components/button/index';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReadyInput } from '../../../../components/input/index';
import { useProtectedApi } from '../../../../hooks/useProtectedApi';
import { City, Paginated } from 'store/Entities';

const schema = yup.object({
  name: yup.string().required('Required')
})

interface Form {
  name: string
}

export const AdminNewCity = () => {
  const { sidebarDialog, setSidebarDialog } = useSideBarDialog();
  const { formState: { errors }, handleSubmit, reset, register } = useForm<Form>({
    resolver: yupResolver(schema)
  });
  const { postData, isLoading } = useProtectedApi({
    route: 'adminCities'
  })
  const { setCities } = useCities();
  const { fetchData } = useProtectedApi<Paginated<City>>({
      route: 'cities',
      storeFunction: ({ data }) => setCities(data as any),
  });
  const closeDialog = () => setSidebarDialog('closed');

  const userInput = handleSubmit(async (data) => {

    await postData(data);

    reset({});

    fetchData(`?paginate=true&page=1`);

    closeDialog()
  });

  return (
    <Sidebar
      rtl="justify-end"
      size={{
        width: 'w-full md:w-[30%]',
      }}
      isOpen={sidebarDialog === 'city'}
      onClose={closeDialog}
      header={{
        title: 'New City',
        icon: <MdLocationCity />,
        action: (
          <button onClick={closeDialog} className='bg-gray-100 rounded-full p-2 text-xl hover:text-primaryBlue transition-all duration-300'>
            <MdClose />
          </button>
        )
      }}
    >
      <form onSubmit={userInput} className='p-4 flex flex-col'>
        <ReadyInput {...register('name')} placeholder='City name' error={errors?.name?.message} />
        <ReadyButton loading={isLoading}>Add city</ReadyButton>
      </form>
    </Sidebar>
  )
}
