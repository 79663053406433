import { RPNameSpaces } from "api/api.config"
import { useStore } from "store"
import { useNavigate } from 'react-router-dom';
import { routes } from '../utils/routes/index';
import { useUser } from '../store/index';

export const useLogout = () => {
    const { setAuth } = useStore();
    const navigate = useNavigate();
    const { setUser } = useUser();
    
    return {
     logout() {
        setAuth(false)

        localStorage.removeItem(RPNameSpaces.isAuth);
        localStorage.removeItem(RPNameSpaces.token);

        setUser(undefined as any);

        navigate(routes.home, { replace: true });
     }
 }
}
