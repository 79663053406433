import { useEffect } from 'react';


export function usePreventReload(disabled: boolean = false) {
    useEffect(() => {
        if(!window) return;

        if (disabled) return;

        window.onbeforeunload = function() {
            return ''
        }

        return() => {
            window.onbeforeunload = null
        }
    }, [disabled])
}