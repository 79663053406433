/* eslint-disable react-hooks/exhaustive-deps */
import { Sidebar } from 'components/Sidebar'
import { MdLocationCity, MdClose } from 'react-icons/md'
import { useSideBarDialog, useCities } from 'store';
import { useForm } from 'react-hook-form';
import { ReadyButton } from 'components/button';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReadyInput } from 'components/input/index';
import { useProtectedApi } from 'hooks/useProtectedApi';
import { City, Paginated } from 'store/Entities';
import { useCurrentCity } from '../../../../../store/index';
import { useEffect } from 'react';

const schema = yup.object({
  name: yup.string().required('Required'),
  amount: yup.number().integer('must be greater than 0').typeError('A number is required').required('Required')
})

interface Form {
  name: string,
  amount: number
}

export const AdminCityChangeFee = () => {
  const { sidebarDialog, setSidebarDialog } = useSideBarDialog();
  const { formState: { errors }, handleSubmit, reset, register, setValue } = useForm<Form>({
    resolver: yupResolver(schema)
  });
  const { currentCity, setCurrentCity } = useCurrentCity();
  const { postData, isLoading } = useProtectedApi({
    route: 'adminCities'
  });

  useEffect(() => {
        setValue('name', currentCity.name);
        setValue('amount', currentCity?.fees?.amount || 0);
  }, [currentCity])

  const { setCities } = useCities();
  const { fetchData } = useProtectedApi<Paginated<City>>({
      route: 'cities',
      storeFunction: ({ data }) => setCities(data as any),
  });
  const closeDialog = () => setSidebarDialog('closed');

  const userInput = handleSubmit(async (data) => {

    await postData(data, `/${currentCity.id}/fees`);

    reset({});

    fetchData(`?paginate=true&page=1`);

    closeDialog()

    setCurrentCity(new City())
  });

  return (
    <Sidebar
      rtl="justify-end"
      size={{
        width: 'w-full md:w-[30%]',
      }}
      isOpen={sidebarDialog === 'fee'}
      onClose={closeDialog}
      header={{
        title: 'Change fee',
        icon: <MdLocationCity />,
        action: (
          <button onClick={closeDialog} className='bg-gray-100 rounded-full p-2 text-xl hover:text-primaryBlue transition-all duration-300'>
            <MdClose />
          </button>
        )
      }}
    >
      <form onSubmit={userInput} className='p-4 flex flex-col'>
        <ReadyInput {...register('name')} placeholder='City name' error={errors?.name?.message} />
        <ReadyInput {...register('amount')} placeholder='10' error={errors?.amount?.message} />
        <ReadyButton loading={isLoading}>Update fee</ReadyButton>
      </form>
    </Sidebar>
  )
}
